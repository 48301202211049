import { INewInterestChartsPopUp } from "./AddNewInterestCharts.type";
import { FC, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTheme } from "@mui/material";
import {
  PersistentDrawer,
  Autocomplete,
  Icon,
  MultiSelect,
  Box,
  Button,
  Input,
  Select,
  Grid,
  Radio,
  Typography,
} from "@ntpkunity/controls";
import { ConfirmationDialog, MultiSelectWrap } from "@sharedComponents";
import {
  IAssetMake,
  IAssetModel,
  IAssetTrim,
  ICharts,
  IInterestChartCriteria,
  IInterestCharts,
  ISelectItemList,
} from "Interface";
import {
  useMutation_AddNewInterestCharts,
  useMutation_AddNewInterestCriteria,
  useMutation_UpdateInterestCharts,
  UseQuery_GetAllAssetMakes,
  UseQuery_GetAllAssetModels,
  UseQuery_GetAllAssetTrims,
  UseMutation_AddCharts,
  UseMutation_UpdateCharts,
  UseMutation_CheckDuplicateInterestName,
  UseMutation_CheckCriteriaAttached,
} from "services";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { logout } from "@ntpkunity/controls-ums";
import {
  ActionOptions,
  ChartTypes,
  DialogMessages,
  QueryKeys,
  ToastMessages,
  ValidationMessages,
} from "Enums";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { SetupContext } from "context";
const NewInterestCharts: FC<INewInterestChartsPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  interestChartData,
  AllBusinessUnits,
  AllOtherCosts,
  financeType,
  customerType,
  assetCategory,
  assetType,
  producttype,
  businessTradingAge,
}) => {
  const { mutate: CheckNameDuplicate } =
    UseMutation_CheckDuplicateInterestName();
  const {
    assetConditions,
    terms,
    creditRatings,
    lenderProfile,
    AllFinancedAmounts,
    assetUsages,
  } = useContext(SetupContext);
  const [state, dispatch] = useSetupsStore();
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    resetField,
    trigger,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty, isValidating },
  } = useForm<IInterestChartCriteria>();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const chartType = "Asset Based";
  const { actions } = useStoreContext();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const onSubmit = (data: IInterestChartCriteria) => {
    data.interestCriteria.asset_make_name = assetMakeId;
    data.interestCriteria.asset_model_name = assetModelId;
    data.interestCriteria.asset_trim_name = assetTrimId;
    data.interestCriteria.terms = assetTerm;
    data.interestCriteria.credit_rating = assetCreditScore;
    data.interestCriteria.asset_condition = assetCondition;
    data.interestCriteria.asset_usages = assetUsage;
    data.interestCriteria.manufacturer = manufacturer;
    data.interestCriteria.purchase_option = purchaseOption;
    data.interestCriteria.business_units = businessUnits;
    data.interestCriteria.cost_name = costName;
    data.interestCriteria.financed_amount = financedAmounts;
    data.interestCriteria.finance_type = financetype;
    data.interestCriteria.customer_type = customertype;
    data.interestCriteria.asset_category = assetcategory;
    data.interestCriteria.asset_type = assettype;
    data.interestCriteria.product_type = productType;
    data.interestCriteria.business_trading_age = tradingage;
    data.interestCriteria.down_payment_range = downPaymentRange;
    data.chart.chart_type_id = 1;
    if (actionType == ActionOptions.ADD_NEW_CHART) {
      if (
        !(data?.interestCriteria.margin >= -100) ||
        data?.interestCriteria.margin == null
      ) {
        if (chartType == "Asset Based") {
          if (
            chartId == null &&
            data?.interestCriteria?.asset_make_name == null &&
            data?.interestCriteria?.asset_model_name == null &&
            data?.interestCriteria?.asset_trim_name == null &&
            data?.interestCriteria?.credit_rating.length == 0 &&
            data?.interestCriteria?.terms.length == 0 &&
            data?.interestCriteria?.asset_condition.length == 0 &&
            data?.interestCriteria?.asset_usages.length == 0 &&
            data?.interestCriteria?.business_units.length == 0 &&
            data?.interestCriteria?.manufacturer.length == 0 &&
            data?.interestCriteria?.purchase_option.length == 0 &&
            data?.interestCriteria?.customer_type.length == 0 &&
            data?.interestCriteria?.finance_type.length == 0 &&
            data?.interestCriteria?.asset_category.length == 0 &&
            data?.interestCriteria?.asset_type.length == 0 &&
            data?.interestCriteria?.product_type.length == 0 &&
            data?.interestCriteria?.business_trading_age.length == 0
          ) {
            AddChart(data.chart, {
              onSuccess: (dataa, variables, context) => {
                setChartId(dataa.data.id);
                setValue("chart.chart_name", dataa.data.chart_name);
                setTypeEnable(true);
                setIsChartAdded(true);
                setChartEnable(true);
                onClose();
              },
              onError: (error) => {
                reset(undefined);
                setValue("chart.chart_name", data.chart.chart_name);
                if (error?.response?.data?.detail?.code === 400) {
                  setError("chart.chart_name", {
                    message: "Chart Name should be unique",
                  });
                  return;
                } else {
                  actions.setToast({
                    toastMessage:
                      error.response.data.detail.message ??
                      ToastMessages.Internal_Server_Error,
                    toastState: true,
                    variant: "error",
                  });
                }
              },
            });
            clearErrors([
              "interestCriteria.maximum_margin",
              "interestCriteria.minimum_margin",
              "interestCriteria.margin",
            ]);
          } else if (
            chartId == null &&
            data?.interestCriteria?.asset_make_name != null &&
            data?.interestCriteria?.asset_model_name != null &&
            data?.interestCriteria?.asset_trim_name != null &&
            data?.interestCriteria?.credit_rating.length != 0 &&
            data?.interestCriteria?.terms.length != 0 &&
            data?.interestCriteria?.asset_condition.length != 0 &&
            data?.interestCriteria?.asset_usages.length != 0 &&
            data?.interestCriteria?.business_units.length != 0 &&
            data?.interestCriteria?.manufacturer.length != 0 &&
            data?.interestCriteria?.purchase_option.length != 0 &&
            data?.interestCriteria?.financed_amount.length != 0 &&
            data?.interestCriteria?.customer_type.length != 0 &&
            data?.interestCriteria?.finance_type.length != 0 &&
            data?.interestCriteria?.asset_category.length != 0 &&
            data?.interestCriteria?.asset_type.length != 0 &&
            data?.interestCriteria?.product_type.length != 0 &&
            data?.interestCriteria?.business_trading_age.length != 0
          ) {
            if (
              data?.interestCriteria?.minimum_margin != null &&
              data?.interestCriteria?.maximum_margin != null
            ) {
              addNewChartWithCriteria(data);
              setChartEnable(true);
              setTypeEnable(true);
            } else {
              setError("interestCriteria.maximum_margin", {
                message: ValidationMessages.MAX_MARGIN_REQUIRED,
              });
              setError("interestCriteria.minimum_margin", {
                message: ValidationMessages.MIN_MARGIN_REQUIRED,
              });
              setError("interestCriteria.margin", {
                message: ValidationMessages.MARGIN_REQUIRED,
              });
            }
          } else {
            setError("interestCriteria.maximum_margin", {
              message: ValidationMessages.MAX_MARGIN_REQUIRED,
            });
            setError("interestCriteria.minimum_margin", {
              message: ValidationMessages.MIN_MARGIN_REQUIRED,
            });
            setError("interestCriteria.margin", {
              message: ValidationMessages.MARGIN_REQUIRED,
            });
          }
        } else {
          if (
            chartId == null &&
            data?.interestCriteria?.cost_name.length == 0 &&
            data?.interestCriteria?.financed_amount.length == 0 &&
            data?.interestCriteria?.business_units.length == 0 &&
            data?.interestCriteria?.terms.length == 0
          ) {
            AddChart(data.chart, {
              onSuccess: (dataa, variables, context) => {
                setChartId(dataa.data.id);
                setValue("chart.chart_name", dataa.data.chart_name);
                setTypeEnable(true);
                setIsChartAdded(true);
                setChartEnable(true);
              },
              onError: (error) => {
                reset(undefined);
                setValue("chart.chart_name", data.chart.chart_name);
                if (error?.response?.data?.detail?.code === 400) {
                  setError("chart.chart_name", {
                    message: "Chart Name should be unique",
                  });
                  return;
                } else {
                  actions.setToast({
                    toastMessage:
                      error.response.data.detail.message ??
                      ToastMessages.Internal_Server_Error,
                    toastState: true,
                    variant: "error",
                  });
                }
              },
            });
            clearErrors([
              "interestCriteria.maximum_margin",
              "interestCriteria.minimum_margin",
              "interestCriteria.margin",
            ]);
          } else if (
            chartId == null &&
            data?.interestCriteria?.cost_name.length != 0 &&
            data?.interestCriteria?.terms.length != 0 &&
            data?.interestCriteria?.financed_amount.length != 0 &&
            data?.interestCriteria?.business_units.length != 0
          ) {
            if (
              data?.interestCriteria?.minimum_margin != null &&
              data?.interestCriteria?.maximum_margin != null
            ) {
              addNewChartWithCriteria(data);
              setChartEnable(true);
              setTypeEnable(true);
            } else {
              setError("interestCriteria.maximum_margin", {
                message: ValidationMessages.MAX_MARGIN_REQUIRED,
              });
              setError("interestCriteria.minimum_margin", {
                message: ValidationMessages.MIN_MARGIN_REQUIRED,
              });
              setError("interestCriteria.margin", {
                message: ValidationMessages.MARGIN_REQUIRED,
              });
            }
          } else {
            setError("interestCriteria.maximum_margin", {
              message: ValidationMessages.MAX_MARGIN_REQUIRED,
            });
            setError("interestCriteria.minimum_margin", {
              message: ValidationMessages.MIN_MARGIN_REQUIRED,
            });
            setError("interestCriteria.margin", {
              message: ValidationMessages.MARGIN_REQUIRED,
            });
          }
        }
      } else if (data?.interestCriteria?.margin >= -100) {
        if (chartId != null) {
          data.interestCriteria.chart_id = chartId;
          clearErrors([
            "interestCriteria.maximum_margin",
            "interestCriteria.minimum_margin",
            "interestCriteria.margin",
          ]);
          addCriteria(data.interestCriteria);
          setIsChartAdded(true);
          setChartEnable(true);
          setTypeEnable(true);
        } else {
          addNewChartWithCriteria(data);
          setIsChartAdded(true);
          setChartEnable(true);
          setTypeEnable(true);
        }
      }
    } else if (actionType == ActionOptions.RENAME_CHART) {
      data.chart.id = interestChartData.chart.id;
      updateChart(data.chart);
    } else if (actionType == ActionOptions.ADD_CRITERIA) {
      if (
        !(data?.interestCriteria.margin >= -100) ||
        data?.interestCriteria.margin == null ||
        data?.interestCriteria.margin == undefined
      ) {
        setError("interestCriteria.margin", {
          message: ValidationMessages.MARGIN_REQUIRED,
        });
      }
      if (
        !(data?.interestCriteria.minimum_margin >= -100) ||
        data?.interestCriteria.minimum_margin == null ||
        data?.interestCriteria.minimum_margin == undefined
      ) {
        setError("interestCriteria.minimum_margin", {
          message: ValidationMessages.MIN_MARGIN_REQUIRED,
        });
      }
      if (
        !(data?.interestCriteria.maximum_margin >= -100) ||
        data?.interestCriteria.maximum_margin == null ||
        data?.interestCriteria.maximum_margin == undefined
      ) {
        setError("interestCriteria.maximum_margin", {
          message: ValidationMessages.MAX_MARGIN_REQUIRED,
        });
      }
      if (data?.interestCriteria.margin_type == null) {
        setError("interestCriteria.margin_type", {
          message: ValidationMessages.MARGIN_TYPE_REQUIRED,
        });
      }
      if (
        data?.interestCriteria?.maximum_margin <
        data?.interestCriteria?.minimum_margin
      ) {
        setError("interestCriteria.maximum_margin", {
          message: ValidationMessages.MAX_MARGIN_GREATER,
        });
      }
      if (
        data?.interestCriteria?.maximum_margin < data?.interestCriteria?.margin
      ) {
        setError("interestCriteria.maximum_margin", {
          message: ValidationMessages.Max_MARGIN_GREATER_THAN_DEFAULT,
        });
      }
      if (
        data?.interestCriteria?.minimum_margin > data?.interestCriteria?.margin
      ) {
        setError("interestCriteria.minimum_margin", {
          message: ValidationMessages.MIN_MARGIN_SMALLER_THAN_DEFAULT,
        });
      } else if (
        data?.interestCriteria.margin_type != null &&
        data?.interestCriteria?.margin >= -100 &&
        data?.interestCriteria?.margin <=
          data?.interestCriteria?.maximum_margin &&
        data?.interestCriteria?.margin >=
          data?.interestCriteria?.minimum_margin &&
        data?.interestCriteria?.maximum_margin >=
          data?.interestCriteria?.minimum_margin &&
        data?.interestCriteria?.minimum_margin >= -100
      ) {
        clearErrors([
          "interestCriteria.maximum_margin",
          "interestCriteria.minimum_margin",
          "interestCriteria.margin",
        ]);
        data.interestCriteria.chart_id = interestChartData.chart.id;
        addCriteria(data.interestCriteria);
      }
      setChartEnable(true);
      setTypeEnable(true);
    }
    //edit criteria
    else if (actionType == ActionOptions.EDIT) {
      data.interestCriteria.chart_id = interestChartData?.chart.id;
      data.interestCriteria.id = interestChartData?.interestCriteria.id;
      updateCriteria(data.interestCriteria);
    }
  };
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
      dispatch(setOpenDrawer(false));
    }
  };

  const { mutate: AddInterestChart } = useMutation_AddNewInterestCharts();
  const { mutate: CheckCriteriaExist } = UseMutation_CheckCriteriaAttached();
  const { mutate: AddChart } = UseMutation_AddCharts();
  const { mutate: UpdateChart } = UseMutation_UpdateCharts();
  const { mutate: AddNewInterestCriteria } =
    useMutation_AddNewInterestCriteria();
  const { mutate: UpdateInterestCriteria } =
    useMutation_UpdateInterestCharts(undefined);

  UseQuery_GetAllAssetMakes();
  UseQuery_GetAllAssetModels();
  UseQuery_GetAllAssetTrims();

  const assetMakes: Array<IAssetMake> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MAKES
  );
  const assetModels: Array<IAssetModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MODELS
  );
  const assetTrims: Array<IAssetTrim> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_TRIMS
  );
  const [assetMakeId, setAssetMakeId] = useState(null);
  const [assetModelId, setAssetModelId] = useState(null);
  const [assetTrimId, setAssetTrimId] = useState(null);
  const [assetMake, setAssetMake] = useState(null);
  const [assetModel, setAssetModel] = useState(null);
  var [assetTerm, setAssetTerm] = useState([]);
  const [assetCondition, setAssetCondition] = useState([]);
  const [assetUsage, setAssetUsage] = useState([]);
  const [assetCreditScore, setAssetCreditScore] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [purchaseOption, setPurchaseOption] = useState([]);
  const [costName, setCostName] = useState([]);
  const [financedAmounts, setFinancedAmounts] = useState([]);
  const [chartId, setChartId] = useState(null);
  const [chartEnable, setChartEnable] = useState<boolean>(false);
  const [criteriaEnable, setCriteriaEnable] = useState<boolean>(false);
  const [typeEnable, setTypeEnable] = useState<boolean>(false);
  const [isChartAdded, setIsChartAdded] = useState<boolean>(false);
  const [financetype, setFinanceType] = useState([]);
  const [customertype, setCustomerType] = useState([]);
  const [assetcategory, setAssetCategory] = useState([]);
  const [assettype, setAssetType] = useState([]);
  const [productType, setProductType] = useState([]);
  const [tradingage, setTradingAge] = useState([]);
  const [downPaymentRange, setDownPaymentRangeSelection] = useState([]);
  const [chartNameValidation, setChartNameValidation] =
    useState<boolean>(false);

  useEffect(() => {
    if (actionType === ActionOptions.RENAME_CHART && interestChartData) {
      CheckCriteriaExist(
        {
          data: `name=${interestChartData.chart?.chart_name}&chart_type=${ChartTypes.InterestChart}`,
        },
        {
          onSuccess: (response) => {
            setTypeEnable(response.data);
          },
        }
      );
      setCriteriaEnable(true);
      setChartEnable(false);
      setValue("chart.chart_name", interestChartData.chart?.chart_name);
      setValue("chart.is_active", interestChartData.chart?.is_active);
    } else if (actionType === ActionOptions.ADD_CRITERIA && interestChartData) {
      setChartEnable(true);
      setCriteriaEnable(false);
      setTypeEnable(true);
      setValue("chart.chart_name", interestChartData.chart?.chart_name);
      setValue("chart.is_active", interestChartData.chart?.is_active);
    } else if (actionType === ActionOptions.ADD_NEW_CHART && !isChartAdded) {
      setChartEnable(false);
      setCriteriaEnable(false);
      setTypeEnable(false);
      reset(undefined);
    }
    //edit criteria
    else if (actionType === ActionOptions.EDIT && interestChartData) {
      setChartEnable(true);
      setCriteriaEnable(false);
      setTypeEnable(true);
      setValue("chart.chart_name", interestChartData?.chart?.chart_name);
      setValue("chart.is_active", interestChartData?.chart?.is_active);
      setValue("interestCriteria.id", interestChartData?.interestCriteria?.id);
      // if(chartType=="Asset Based"){
      setAssetMakeId(interestChartData?.interestCriteria?.asset_make_name);
      setAssetModelId(interestChartData?.interestCriteria?.asset_model_name);
      setAssetTrimId(interestChartData?.interestCriteria?.asset_trim_name);
      if (interestChartData?.interestCriteria?.asset_condition) {
        setAssetCondition(interestChartData?.interestCriteria?.asset_condition);
      }
      if (interestChartData?.interestCriteria?.asset_usages) {
        setAssetUsage(interestChartData?.interestCriteria?.asset_usages);
      }
      if (interestChartData?.interestCriteria?.credit_rating) {
        setAssetCreditScore(interestChartData?.interestCriteria?.credit_rating);
      }
      if (interestChartData?.interestCriteria?.manufacturer) {
        setManufacturer(interestChartData?.interestCriteria?.manufacturer);
      }
      if (interestChartData?.interestCriteria?.purchase_option) {
        setPurchaseOption(interestChartData?.interestCriteria?.purchase_option);
      }
      if (interestChartData?.interestCriteria?.business_units) {
        setBusinessUnits(interestChartData?.interestCriteria?.business_units);
      }
      if (interestChartData?.interestCriteria?.cost_name) {
        setCostName(interestChartData?.interestCriteria?.cost_name);
      }
      if (interestChartData?.interestCriteria?.financed_amount) {
        setFinancedAmounts(
          interestChartData?.interestCriteria?.financed_amount
        );
      }
      if (interestChartData?.interestCriteria?.finance_type) {
        setFinanceType(interestChartData?.interestCriteria?.finance_type);
      }
      if (interestChartData?.interestCriteria?.customer_type) {
        setCustomerType(interestChartData?.interestCriteria?.customer_type);
      }
      if (interestChartData?.interestCriteria?.asset_type) {
        setAssetType(interestChartData?.interestCriteria?.asset_type);
      }
      if (interestChartData?.interestCriteria?.asset_category) {
        setAssetCategory(interestChartData?.interestCriteria?.asset_category);
      }
      if (interestChartData?.interestCriteria?.product_type) {
        setProductType(interestChartData?.interestCriteria?.product_type);
      }
      if (interestChartData?.interestCriteria?.business_trading_age) {
        setTradingAge(
          interestChartData?.interestCriteria?.business_trading_age
        );
      }
      if (interestChartData?.interestCriteria?.down_payment_range) {
        setDownPaymentRangeSelection(
          interestChartData?.interestCriteria?.down_payment_range
        );
      }
      setValue(
        "interestCriteria.asset_condition",
        interestChartData?.interestCriteria?.asset_condition
      );
      if (interestChartData?.interestCriteria?.terms) {
        setAssetTerm(interestChartData?.interestCriteria?.terms);
      }
      setValue(
        "interestCriteria.finance_type",
        interestChartData?.interestCriteria?.finance_type
      );
      setValue(
        "interestCriteria.customer_type",
        interestChartData?.interestCriteria?.customer_type
      );
      setValue(
        "interestCriteria.asset_category",
        interestChartData?.interestCriteria?.asset_category
      );
      setValue(
        "interestCriteria.asset_type",
        interestChartData?.interestCriteria?.asset_type
      );
      setValue(
        "interestCriteria.product_type",
        interestChartData?.interestCriteria?.product_type
      );
      setValue(
        "interestCriteria.business_trading_age",
        interestChartData?.interestCriteria?.business_trading_age
      );
      setValue(
        "interestCriteria.margin",
        interestChartData?.interestCriteria?.margin
      );
      setValue(
        "interestCriteria.minimum_margin",
        interestChartData?.interestCriteria?.minimum_margin
      );
      setValue(
        "interestCriteria.maximum_margin",
        interestChartData?.interestCriteria?.maximum_margin
      );
    }
  }, [interestChartData, criteriaEnable]);

  const [financeTypeSelection, setFinancedTypeSelection] =
    useState<Array<ISelectItemList>>();
  const [customerTypeSelection, setCustomerTypeSelection] =
    useState<Array<ISelectItemList>>();
  const [assetCategorySelection, setAssetCategorySelection] =
    useState<Array<ISelectItemList>>();
  const [assetTypeSelection, setAssetTypeSelection] =
    useState<Array<ISelectItemList>>();
  const [productTypeSelection, setProductTypeSelection] =
    useState<Array<ISelectItemList>>();
  const [tradingAgeSelection, setTradingAgeSelection] =
    useState<Array<ISelectItemList>>();
  const [downPaymentRangeSelection] = useState<Array<ISelectItemList>>([
    { value: "0.00% - 5.00%", text: "0.00% - 5.00%" },
    { value: "5.01% - 10.00%", text: "5.01% - 10.00%" },
    { value: "10.01% - 15.00%", text: "10.01% - 15.00%" },
    { value: "15.01% - 20.00%", text: "15.01% - 20.00%" },
    { value: "20.01% - 25.00%", text: "20.01% - 25.00%" },
    { value: "25.01% - 30.00%", text: "25.01% - 30.00%" },
  ]);

  const [assetmakesSelection, setAssetmakesSelection] = useState<
    Array<ISelectItemList>
  >(
    assetMakes
      ?.filter((x) => x.is_active)
      .map((make) => ({
        value: make.id,
        text: make.name,
      }))
  );

  useEffect(() => {
    setAssetmakesSelection(
      assetMakes
        ?.filter((x) => x.is_active)
        .map((makes) => ({
          value: makes.name,
          text: makes.name,
        }))
    );
  }, [assetMakes, assetMakeId]);

  const [assetmodelsSelection, setAssetmodelsSelection] = useState<
    Array<ISelectItemList>
  >([]);

  const [assetTrimSelection, setAssettrimSelection] = useState<
    Array<ISelectItemList>
  >([]);

  const [creditRatingsSelection, setCreditRatingState] =
    useState<Array<ISelectItemList>>();

  useEffect(() => {
    const ratingsArray = creditRatings
      ?.filter((x) => x.is_active)
      ?.map((creditRating) => ({
        value: creditRating.description,
        text: creditRating.description,
      }));
    assetCreditScore?.forEach((desc) => {
      const selectedRating = creditRatings?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedRating &&
        ratingsArray.push({
          value: selectedRating?.description,
          text: selectedRating?.description,
        });
    });
    setCreditRatingState(ratingsArray);
  }, [creditRatings, assetCreditScore]);

  const [termsSelection, setTermsSelectionState] =
    useState<Array<ISelectItemList>>();
  const [assetConditionSelection, setAssetConditionSelectionState] =
    useState<Array<ISelectItemList>>();
  const [assetUsageSelection, setAssetUsageSelectionState] =
    useState<Array<ISelectItemList>>();

  const [businessUnitsSelection, setBusinessUnitsSelectionState] =
    useState<Array<ISelectItemList>>();

  const [otherCostsSelection, setOtherCostsSelectionState] =
    useState<Array<ISelectItemList>>();

  const [financedAmountsSelection, setFinancedAmountsSelectionState] =
    useState<Array<ISelectItemList>>();

  useEffect(() => {
    const termsArray = terms
      ?.filter((x) => x.is_active)
      ?.map((term) => ({
        value: `${term.terms_from} - ${term.terms_to}`,
        text: `${term.terms_from} - ${term.terms_to}`,
      }));
    assetTerm?.forEach((id) => {
      const selectedTerms = terms?.find((x) => x.id == id && !x.is_active);
      selectedTerms &&
        termsArray.push({
          value: `${selectedTerms.terms_from} - ${selectedTerms.terms_to}`,
          text: `${selectedTerms.terms_from} - ${selectedTerms.terms_to}`,
        });
    });
    setTermsSelectionState(termsArray);
  }, [terms, assetTerm]);

  useEffect(() => {
    const financedAmountArray = AllFinancedAmounts?.filter(
      (x) => x.is_active
    )?.map((financed) => ({
      value: `${financed.financed_amount_from} - ${financed.financed_amount_to}`,
      text: `${financed.financed_amount_from.toLocaleString()} - ${financed.financed_amount_to.toLocaleString()}`,
    }));
    financedAmounts?.forEach((id) => {
      const selectedFinancedAmounts = AllFinancedAmounts?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedFinancedAmounts &&
        financedAmountArray.push({
          value: `${selectedFinancedAmounts.financed_amount_from} - ${selectedFinancedAmounts.financed_amount_to}`,
          text: `${selectedFinancedAmounts.financed_amount_from.toLocaleString()} - ${selectedFinancedAmounts.financed_amount_to.toLocaleString()}`,
        });
    });
    setFinancedAmountsSelectionState(financedAmountArray);
  }, [AllFinancedAmounts, financedAmounts]);

  useEffect(() => {
    const businessUnitArray = AllBusinessUnits?.filter((x) => x.is_active)?.map(
      (business) => ({
        value: business.description,
        text: business.description,
      })
    );
    businessUnits?.forEach((desc) => {
      const selectedBusinessUnits = AllBusinessUnits?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedBusinessUnits &&
        businessUnitArray.push({
          value: selectedBusinessUnits?.description,
          text: selectedBusinessUnits?.description,
        });
    });
    setBusinessUnitsSelectionState(businessUnitArray);
  }, [AllBusinessUnits, businessUnits]);

  useEffect(() => {
    const otherCostArray = AllOtherCosts?.filter((x) => x.is_active)?.map(
      (cost) => ({
        value: cost.description,
        text: cost.description,
      })
    );
    costName?.forEach((desc) => {
      const selectedOtherCosts = AllOtherCosts?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedOtherCosts &&
        otherCostArray.push({
          value: selectedOtherCosts?.description,
          text: selectedOtherCosts?.description,
        });
    });
    setOtherCostsSelectionState(otherCostArray);
  }, [AllOtherCosts, costName]);

  useEffect(() => {
    const assetUsageArray = assetUsages
      ?.filter((x) => x.is_active)
      ?.map((asset) => ({
        value: `${asset.description}`,
        text: `${asset.description}`,
      }));
    assetUsage.forEach((desc) => {
      const selectedAssetUsages = assetUsages?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedAssetUsages &&
        assetUsageArray.push({
          value: selectedAssetUsages?.description,
          text: selectedAssetUsages?.description,
        });
    });
    setAssetUsageSelectionState(assetUsageArray);
  }, [assetUsages, assetUsage]);

  useEffect(() => {
    const assetConditionArray = assetConditions
      ?.filter((x) => x.is_active)
      ?.map((asset) => ({
        value: `${asset.description}`,
        text: `${asset.description}`,
      }));
    assetCondition.forEach((desc) => {
      const selectedAssetConditions = assetConditions?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedAssetConditions &&
        assetConditionArray.push({
          value: selectedAssetConditions?.description,
          text: selectedAssetConditions?.description,
        });
    });
    setAssetConditionSelectionState(assetConditionArray);
  }, [assetConditions, assetCondition]);

  useEffect(() => {
    setAssetMake(assetMakes?.filter((x) => x.name == assetMakeId)[0]?.id);
    setAssetmodelsSelection(
      assetModels
        ?.filter(
          (models) =>
            assetMake > 0 && models.make_id === assetMake && models.is_active
        )
        ?.map((model) => ({
          value: model.model_name,
          text: model.model_name,
        }))
    );
  }, [assetModels, assetMakes, assetMakeId, assetModelId, assetMake]);

  useEffect(() => {
    setAssetModel(
      assetModels?.filter((x) => x.model_name == assetModelId)[0]?.id
    );
    setAssettrimSelection(
      assetTrims
        ?.filter(
          (trims) =>
            assetModel > 0 && trims.model_id === assetModel && trims.is_active
        )
        ?.map((trim) => ({
          value: trim.trim_name,
          text: trim.trim_name,
        }))
    );
  }, [
    assetTrims,
    assetModels,
    assetMakes,
    assetMakeId,
    assetModelId,
    assetTrimId,
    assetModel,
  ]);

  useEffect(() => {
    const financedTypeArray = financeType
      ?.filter((x) => x.is_active)
      ?.map((finance) => ({
        value: `${finance.name}`,
        text: `${finance.name}`,
      }));
    financetype.forEach((id) => {
      const selectedFinancedType = financeType?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedFinancedType &&
        financedTypeArray.push({
          value: selectedFinancedType?.name,
          text: selectedFinancedType?.name,
        });
    });
    setFinancedTypeSelection(financedTypeArray);
  }, [financeType, financetype]);

  useEffect(() => {
    const customerTypeArray = customerType
      ?.filter((x) => x.is_active)
      ?.map((customer) => ({
        value: `${customer.description}`,
        text: `${customer.description}`,
      }));
    customertype.forEach((id) => {
      const selectedCustomerType = customerType?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedCustomerType &&
        customerTypeArray.push({
          value: selectedCustomerType?.description,
          text: selectedCustomerType?.description,
        });
    });
    setCustomerTypeSelection(customerTypeArray);
  }, [customerType, customertype]);

  useEffect(() => {
    const assetCategoryArray = assetCategory
      ?.filter((x) => x.is_active)
      ?.map((category) => ({
        value: `${category.description}`,
        text: `${category.description}`,
      }));
    assetcategory.forEach((id) => {
      const selectedAssetCategory = assetCategory?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedAssetCategory &&
        assetCategoryArray.push({
          value: selectedAssetCategory?.description,
          text: selectedAssetCategory?.description,
        });
    });
    setAssetCategorySelection(assetCategoryArray);
  }, [assetCategory, assetcategory]);

  useEffect(() => {
    const assetTypeArray = assetType
      ?.filter((x) => x.is_active || x.is_active === null)
      ?.map((asset) => ({
        value: `${asset.name}`,
        text: `${asset.name}`,
      }));
    assettype.forEach((id) => {
      const selectedAssetType = assetType?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedAssetType &&
        assetTypeArray.push({
          value: selectedAssetType?.name,
          text: selectedAssetType?.name,
        });
    });
    setAssetTypeSelection(assetTypeArray);
  }, [assetType, assettype]);

  useEffect(() => {
    const productTypeArray = producttype
      ?.filter((x) => x.is_active || x.is_active === null)
      ?.map((asset) => ({
        value: `${asset.name}`,
        text: `${asset.name}`,
      }));
    productType.forEach((id) => {
      const selectedProductType = producttype?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedProductType &&
        productTypeArray.push({
          value: selectedProductType?.name,
          text: selectedProductType?.name,
        });
    });
    setProductTypeSelection(productTypeArray);
  }, [producttype, productType]);

  useEffect(() => {
    const tradingAgeArray = businessTradingAge
      ?.filter((x) => x.is_active)
      ?.map((age) => ({
        value: `${age.age_from} - ${age.age_to}`,
        text: `${age.age_from} - ${age.age_to}`,
      }));
    businessTradingAge?.forEach((age) => {
      const selectedTragingAge = businessTradingAge?.find(
        (x) => x.id == age.id && !x.is_active
      );
      selectedTragingAge &&
        tradingAgeArray.push({
          value: `${selectedTragingAge.age_from} - ${selectedTragingAge.age_to}`,
          text: `${selectedTragingAge.age_from} - ${selectedTragingAge.age_to}`,
        });
    });
    setTradingAgeSelection(tradingAgeArray);
  }, [businessTradingAge, tradingage]);

  const onMakeSelection = (value) => {
    setAssetMakeId(value?.id);
    setAssetModelId(null);
    setAssetTrimId(null);
  };

  const onModelSelection = (value) => {
    setAssetModelId(value?.id);
    setAssetTrimId(null);
  };

  const onTrimSelection = (value) => {
    setAssetTrimId(value?.id);
  };

  const onTermsSelection = (e) => {
    setAssetTerm(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onFinanceTypeSelection = (e) => {
    setFinanceType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onCustomerTypeSelection = (e) => {
    setCustomerType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onAssetCategorySelection = (e) => {
    setAssetCategory(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onAssetTypeSelection = (e) => {
    setAssetType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onProductTypeSelection = (e) => {
    setProductType(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onTradingAgeSelection = (e) => {
    setTradingAge(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onDownPaymentRangeSelection = (e) => {
    setDownPaymentRangeSelection(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onCreditScoreSelection = (e) => {
    setAssetCreditScore(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onConditionSelection = (e) => {
    setAssetCondition(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onUsageSelection = (e) => {
    setAssetUsage(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onManufacturerSelection = (e) => {
    setManufacturer(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onBusinessUnitsSelection = (e) => {
    setBusinessUnits(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onPurchaseOptionSelection = (e) => {
    setPurchaseOption(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onFinancedAmountSelection = (e) => {
    setFinancedAmounts(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onCostNameSelection = (e) => {
    setCostName(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const addNewChartWithCriteria = (data: IInterestChartCriteria) => {
    if (interestChartData?.chart?.id > 0) {
      data.chartId = interestChartData?.chart?.id;
    }
    if (
      !(data?.interestCriteria.margin >= -100) ||
      data?.interestCriteria.margin == null ||
      isNaN(data?.interestCriteria.margin)
    ) {
      setError("interestCriteria.margin", {
        message: ValidationMessages.MARGIN_REQUIRED,
      });
    }
    if (
      !(data?.interestCriteria.minimum_margin >= -100) ||
      data?.interestCriteria.minimum_margin == null ||
      isNaN(data?.interestCriteria.minimum_margin)
    ) {
      setError("interestCriteria.minimum_margin", {
        message: ValidationMessages.MIN_MARGIN_REQUIRED,
      });
    }
    if (
      !(data?.interestCriteria.maximum_margin >= -100) ||
      data?.interestCriteria.maximum_margin == null ||
      isNaN(data?.interestCriteria.maximum_margin)
    ) {
      setError("interestCriteria.maximum_margin", {
        message: ValidationMessages.MAX_MARGIN_REQUIRED,
      });
    }
    if (
      data?.interestCriteria.margin_type == null ||
      data?.interestCriteria.margin_type == undefined
    ) {
      setError("interestCriteria.margin_type", {
        message: ValidationMessages.MARGIN_TYPE_REQUIRED,
      });
    }
    if (
      !(data?.interestCriteria.maximum_margin >= -100) ||
      data?.interestCriteria.maximum_margin == null ||
      isNaN(data?.interestCriteria.maximum_margin)
    ) {
      setError("interestCriteria.maximum_margin", {
        message: ValidationMessages.MAX_MARGIN_REQUIRED,
      });
    }
    if (
      data?.interestCriteria.maximum_margin <
      data?.interestCriteria.minimum_margin
    ) {
      setError("interestCriteria.maximum_margin", {
        message: ValidationMessages.MAX_MARGIN_GREATER,
      });
    }
    if (
      data?.interestCriteria?.maximum_margin < data?.interestCriteria?.margin
    ) {
      setError("interestCriteria.maximum_margin", {
        message: ValidationMessages.Max_MARGIN_GREATER_THAN_DEFAULT,
      });
    }
    if (
      data?.interestCriteria?.minimum_margin > data?.interestCriteria?.margin
    ) {
      setError("interestCriteria.minimum_margin", {
        message: ValidationMessages.MIN_MARGIN_SMALLER_THAN_DEFAULT,
      });
    } else if (
      data?.interestCriteria.margin_type != null &&
      data?.interestCriteria?.margin >= -100 &&
      data?.interestCriteria?.margin <=
        data?.interestCriteria?.maximum_margin &&
      data?.interestCriteria?.margin >=
        data?.interestCriteria?.minimum_margin &&
      data?.interestCriteria?.maximum_margin >=
        data?.interestCriteria?.minimum_margin &&
      data?.interestCriteria?.minimum_margin >= -100
    ) {
      clearErrors([
        "interestCriteria.maximum_margin",
        "interestCriteria.minimum_margin",
        "interestCriteria.margin",
      ]);
      AddInterestChart(data, {
        onSuccess: (data, variables, context) => {
          setChartId(data.data.id);
        },
      });
      setAssetMakeId(undefined);
      setAssetModelId(null);
      setAssetTrimId(null);
      setAssetCreditScore([]);
      setAssetTerm([]);
      setAssetCondition([]);
      setAssetUsage([]);
      resetField("interestCriteria.margin");
      resetField("interestCriteria.maximum_margin");
      resetField("interestCriteria.minimum_margin");
      onClose();
    }
  };

  const addCriteria = (data: IInterestCharts) => {
    if (
      !(data?.margin >= -100) ||
      data?.margin == null ||
      isNaN(data?.margin)
    ) {
      setError("interestCriteria.margin", {
        message: ValidationMessages.MARGIN_REQUIRED,
      });
    }
    if (
      !(data?.minimum_margin >= -100) ||
      data?.minimum_margin == null ||
      isNaN(data?.minimum_margin)
    ) {
      setError("interestCriteria.minimum_margin", {
        message: ValidationMessages.MIN_MARGIN_REQUIRED,
      });
    }
    if (
      !(data?.maximum_margin >= -100) ||
      data?.maximum_margin == null ||
      isNaN(data?.maximum_margin)
    ) {
      setError("interestCriteria.maximum_margin", {
        message: ValidationMessages.MAX_MARGIN_REQUIRED,
      });
    }
    if (data?.margin_type == null || data?.margin_type == undefined) {
      setError("interestCriteria.margin_type", {
        message: ValidationMessages.MARGIN_TYPE_REQUIRED,
      });
    }
    if (
      !(data?.maximum_margin >= -100) ||
      data?.maximum_margin == null ||
      isNaN(data?.maximum_margin)
    ) {
      setError("interestCriteria.maximum_margin", {
        message: ValidationMessages.MAX_MARGIN_REQUIRED,
      });
    }
    if (data?.maximum_margin < data?.minimum_margin) {
      setError("interestCriteria.maximum_margin", {
        message: ValidationMessages.MAX_MARGIN_GREATER,
      });
    } else if (
      data?.margin_type != null &&
      data.margin >= -100 &&
      data?.margin <= data?.maximum_margin &&
      data?.margin >= data?.minimum_margin &&
      data.maximum_margin >= data.minimum_margin &&
      data.minimum_margin >= -100
    ) {
      AddNewInterestCriteria(data);
      setAssetMakeId(null);
      setAssetModelId(null);
      setAssetTrimId(null);
      setAssetCreditScore([]);
      setAssetTerm([]);
      setAssetCondition([]);
      setAssetUsage([]);
      setManufacturer([]);
      setBusinessUnits([]);
      setPurchaseOption([]);
      setCostName([]);
      setFinancedAmounts([]);
      setFinanceType([]);
      setCustomerType([]);
      setAssetCategory([]);
      setAssetType([]);
      setProductType([]);
      setTradingAge([]);
      setDownPaymentRangeSelection([]);
      resetField("interestCriteria.asset_make_name");
      resetField("interestCriteria.terms");
      resetField("interestCriteria.credit_rating");
      resetField("interestCriteria.asset_condition");
      resetField("interestCriteria.finance_type");
      resetField("interestCriteria.customer_type");
      resetField("interestCriteria.asset_category");
      resetField("interestCriteria.asset_type");
      resetField("interestCriteria.product_type");
      resetField("interestCriteria.business_trading_age");
      resetField("interestCriteria.margin");
      resetField("interestCriteria.maximum_margin");
      resetField("interestCriteria.minimum_margin");
      resetField("interestCriteria.business_units");
      onClose();
    }
  };
  const updateCriteria = (data: IInterestCharts) => {
    if (
      !(data?.margin >= -100) ||
      data?.margin == null ||
      isNaN(data?.margin)
    ) {
      setError("interestCriteria.margin", {
        message: ValidationMessages.MARGIN_REQUIRED,
      });
    }
    if (
      !(data?.minimum_margin >= -100) ||
      data?.minimum_margin == null ||
      isNaN(data?.minimum_margin)
    ) {
      setError("interestCriteria.minimum_margin", {
        message: ValidationMessages.MIN_MARGIN_REQUIRED,
      });
    }
    if (
      !(data?.maximum_margin >= -100) ||
      data?.maximum_margin == null ||
      isNaN(data?.maximum_margin)
    ) {
      setError("interestCriteria.maximum_margin", {
        message: ValidationMessages.MAX_MARGIN_REQUIRED,
      });
    }
    if (data?.margin_type == null || data?.margin_type == undefined) {
      setError("interestCriteria.margin_type", {
        message: ValidationMessages.MARGIN_TYPE_REQUIRED,
      });
    }
    if (
      !(data?.maximum_margin >= -100) ||
      data?.maximum_margin == null ||
      isNaN(data?.maximum_margin)
    ) {
      setError("interestCriteria.maximum_margin", {
        message: ValidationMessages.MAX_MARGIN_REQUIRED,
      });
    }
    if (data?.maximum_margin < data?.minimum_margin) {
      setError("interestCriteria.maximum_margin", {
        message: ValidationMessages.MAX_MARGIN_GREATER,
      });
    } else if (
      data?.margin_type != null &&
      data.margin >= -100 &&
      data?.margin <= data?.maximum_margin &&
      data?.margin >= data?.minimum_margin &&
      data.maximum_margin >= data.minimum_margin &&
      data.minimum_margin >= -100
    ) {
      clearErrors([
        "interestCriteria.maximum_margin",
        "interestCriteria.minimum_margin",
        "interestCriteria.margin",
      ]);
      UpdateInterestCriteria(data);
      setAssetMakeId(null);
      setAssetModelId(null);
      setAssetTrimId(null);
      setManufacturer([]);
      setBusinessUnits([]);
      setPurchaseOption([]);
      setFinancedAmounts([]);
      setCostName([]);
      setFinancedAmounts([]);
      setFinanceType([]);
      setCustomerType([]);
      setAssetCategory([]);
      setAssetType([]);
      setProductType([]);
      setTradingAge([]);
      setDownPaymentRangeSelection([]);
      resetField("interestCriteria.terms");
      resetField("interestCriteria.financed_amount");
      resetField("interestCriteria.manufacturer");
      resetField("interestCriteria.business_units");
      resetField("interestCriteria.purchase_option");
      resetField("interestCriteria.cost_name");
      resetField("interestCriteria.asset_condition");
      resetField("interestCriteria.finance_type");
      resetField("interestCriteria.customer_type");
      resetField("interestCriteria.asset_category");
      resetField("interestCriteria.asset_type");
      resetField("interestCriteria.product_type");
      resetField("interestCriteria.business_trading_age");
      resetField("interestCriteria.margin");
      resetField("interestCriteria.maximum_margin");
      resetField("interestCriteria.minimum_margin");
      onClose();
    }
  };

  const updateChart = (data: ICharts) => {
    UpdateChart(data);
    reset();
    onClose();
  };
  const setMarginValue = (val) => {
    if (val <= 100 && val >= -100) {
      const minMargin = watch("interestCriteria.minimum_margin");
      const maxMargin = watch("interestCriteria.maximum_margin");
      setValue("interestCriteria.margin", parseFloat(val));
      clearErrors("interestCriteria.margin");
      if (val < minMargin) {
        setError("interestCriteria.minimum_margin", {
          message: ValidationMessages.MIN_SMALLER_THAN_DEFAULT,
        });
      } else {
        clearErrors("interestCriteria.minimum_margin");
      }
      if (val > maxMargin) {
        setError("interestCriteria.maximum_margin", {
          message: ValidationMessages.Max_GREATER_THAN_DEFAULT,
        });
      } else {
        clearErrors([
          // "interestCriteria.margin",

          "interestCriteria.maximum_margin",
        ]);
      }
    }
  };
  const setMinMargin = (val) => {
    if (val <= 100 && val >= -100) {
      const maxMargin = watch("interestCriteria.maximum_margin");
      const margin = watch("interestCriteria.margin") || 0;
      setValue("interestCriteria.minimum_margin", parseFloat(val));
      if (val > margin) {
        setError("interestCriteria.minimum_margin", {
          message: ValidationMessages.MIN_MARGIN_SMALLER_THAN_DEFAULT,
        });
      } else if (val > maxMargin) {
        setError("interestCriteria.maximum_margin", {
          message: ValidationMessages.MAX_MARGIN_GREATER,
        });
      } else {
        clearErrors(["interestCriteria.minimum_margin"]);
      }
    }
  };
  const setMaxMargin = (val) => {
    if (val <= 100 && val >= -100) {
      setValue("interestCriteria.maximum_margin", parseFloat(val));
      const minMargin = watch("interestCriteria.minimum_margin");
      const margin = watch("interestCriteria.margin");
      if (minMargin > val) {
        setError("interestCriteria.maximum_margin", {
          message: ValidationMessages.MAX_MARGIN_GREATER,
        });
      } else if (margin > val) {
        setError("interestCriteria.maximum_margin", {
          message: ValidationMessages.Max_MARGIN_GREATER_THAN_DEFAULT,
        });
      } else {
        clearErrors([
          "interestCriteria.minimum_margin",
          "interestCriteria.maximum_margin",
        ]);
      }
    } else {
      const num = val.substr(0, 2);
      setValue("interestCriteria.maximum_margin", num);
    }
  };

  const checkInterestChartName = (data: string) => {
    CheckNameDuplicate(
      {
        data: `name=${data}`,
      },
      {
        onSuccess: (response) => {
          setChartNameValidation(response.data);
          trigger("chart.chart_name");
          if (response.data) {
            setError(
              "chart.chart_name",
              {
                message: ValidationMessages.NAME_UNIQUE,
              },
              { shouldFocus: true }
            );
          } else {
            clearErrors("chart.chart_name");
          }
        },
      }
    );
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };

  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        onClose={onClose}
        enabledPin={true}
        children={
          <>
            <Controller
              name="chart.chart_name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: ValidationMessages.CHART_NAME_REQUIRED,
                },
                validate: {
                  checkUniqueName: () => {
                    if (chartNameValidation) {
                      return ValidationMessages.NAME_UNIQUE;
                    }
                    return true;
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerChartName"
                  fullWidth={true}
                  theme={theme}
                  label={"Chart Name"}
                  placeholder={"Type Here"}
                  value={value?.trimStart()}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  onBlur={(e) => checkInterestChartName(e.target.value)}
                  disabled={chartEnable}
                  error={errors?.chart?.chart_name?.message}
                  type="text"
                />
              )}
            />
            <Controller
              name="chart.is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerStatus"
                  theme={theme}
                  label={"Status"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                  disabled={chartEnable}
                ></Select>
              )}
            />
            <Box theme={theme} className="divider-with-text">
              <Box theme={theme} className="divider-text">
                BUSINESS CRITERIA
              </Box>
            </Box>
            <>
              <Controller
                name="interestCriteria.asset_make_name"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    disablePortal
                    {...field}
                    id="drawerMake"
                    theme={theme}
                    disabled={criteriaEnable}
                    capitalizeLabel={true}
                    items={
                      assetmakesSelection
                        ? assetmakesSelection?.map((option: any) => {
                            return { label: option.text, id: option.value };
                          })
                        : []
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_e: any, value: any) => {
                      onMakeSelection(value);
                      field.onChange(value);
                    }}
                    label={assetMakeId ? "Asset Make" : "Asset Make"}
                    value={
                      assetMakeId
                        ? {
                            label: assetMakeId,
                            id: assetMakeId,
                          }
                        : null
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id}>
                        <span>{option.label}</span>
                      </li>
                    )}
                  />
                )}
              />
              <Controller
                name="interestCriteria.asset_model_name"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    disablePortal
                    id="drawerModel"
                    {...field}
                    theme={theme}
                    disabled={criteriaEnable}
                    capitalizeLabel={true}
                    items={
                      assetmodelsSelection
                        ? assetmodelsSelection?.map((option: any) => {
                            return { label: option.text, id: option.text };
                          })
                        : []
                    }
                    onChange={(_e: any, value: any) => {
                      onModelSelection(value);
                      field.onChange(value);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    label={assetModelId ? "Asset Model" : "Asset Model"}
                    value={
                      assetModelId
                        ? {
                            label: assetModelId,
                            id: assetModelId,
                          }
                        : null
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id}>
                        <span>{option.label}</span>
                      </li>
                    )}
                  />
                )}
              />
              <Controller
                name="interestCriteria.asset_trim_name"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    disablePortal
                    id="drawerTrim"
                    {...field}
                    theme={theme}
                    disabled={criteriaEnable}
                    capitalizeLabel={true}
                    items={
                      assetTrimSelection
                        ? assetTrimSelection?.map((option: any) => {
                            return { label: option.text, id: option.text };
                          })
                        : []
                    }
                    onChange={(_e: any, value: any) => {
                      onTrimSelection(value);
                      field.onChange(value);
                    }}
                    label={assetTrimId ? "Asset Trim" : "Asset Trim"}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={
                      assetTrimId
                        ? {
                            label: assetTrimId,
                            id: assetTrimId,
                          }
                        : null
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id}>
                        <span>{option.label}</span>
                      </li>
                    )}
                  />
                )}
              />
              <MultiSelectWrap>
                <Controller
                  name="interestCriteria.manufacturer"
                  control={control}
                  render={({ field: { value } }) => (
                    <MultiSelect
                      theme={theme}
                      id="DrawerManufacturer"
                      sxProps={{ m: 0, width: "100%" }}
                      label={"Manufacturer"}
                      value={manufacturer || []}
                      items={[
                        { text: "Manufacturer 1", value: "Manufacturer 1" },
                        { text: "Manufacturer 2", value: "Manufacturer 2" },
                        { text: "Manufacturer 3", value: "Manufacturer 3" },
                        { text: "Manufacturer 4", value: "Manufacturer 4" },
                      ]}
                      onChange={onManufacturerSelection}
                      disabled={criteriaEnable}
                    />
                  )}
                />
              </MultiSelectWrap>
              <MultiSelectWrap>
                <Controller
                  name="interestCriteria.purchase_option"
                  control={control}
                  render={({ field: { value } }) => (
                    <MultiSelect
                      theme={theme}
                      id="DrawerPurchaseOption"
                      sxProps={{ m: 0, width: "100%" }}
                      label={"Purchase Option"}
                      value={purchaseOption || []}
                      items={[
                        { text: "$1 out", value: "$1 out" },
                        { text: "FMV", value: "FMV" },
                      ]}
                      onChange={onPurchaseOptionSelection}
                      disabled={criteriaEnable}
                    />
                  )}
                />
              </MultiSelectWrap>
              <MultiSelectWrap>
                <Controller
                  name="interestCriteria.asset_condition"
                  control={control}
                  render={({ field: { value } }) => (
                    <MultiSelect
                      theme={theme}
                      id="DrawerAssetCondition"
                      sxProps={{ m: 0, width: "100%" }}
                      label={"Asset Condition"}
                      value={assetCondition || []}
                      items={assetConditionSelection}
                      onChange={onConditionSelection}
                      disabled={criteriaEnable}
                    />
                  )}
                />
              </MultiSelectWrap>
              <MultiSelectWrap>
                <Controller
                  name="interestCriteria.asset_usages"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <MultiSelect
                      theme={theme}
                      id="DrawerAssetUsage"
                      sxProps={{ m: 0, width: "100%" }}
                      label={"Asset Usage"}
                      value={assetUsage || []}
                      items={assetUsageSelection}
                      onChange={onUsageSelection}
                      disabled={criteriaEnable}
                    />
                  )}
                />
              </MultiSelectWrap>
              <MultiSelectWrap>
                <Controller
                  name="interestCriteria.credit_rating"
                  control={control}
                  render={({ field }) => (
                    <MultiSelect
                      id="drawerCreditRating"
                      theme={theme}
                      label={"Credit Score"}
                      items={creditRatingsSelection}
                      value={assetCreditScore || []}
                      onChange={onCreditScoreSelection}
                      disabled={criteriaEnable}
                      sxProps={{ m: 0, width: "100%" }}
                    />
                  )}
                />
              </MultiSelectWrap>
            </>
            <MultiSelectWrap>
              <Controller
                name="interestCriteria.financed_amount"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerFinancedAmountRange"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Financed Amount Range"}
                    value={financedAmounts || []}
                    items={financedAmountsSelection}
                    onChange={onFinancedAmountSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="interestCriteria.terms"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MultiSelect
                    theme={theme}
                    id="drawerTerms"
                    label={"Terms"}
                    value={assetTerm || []}
                    items={termsSelection}
                    onChange={onTermsSelection}
                    disabled={criteriaEnable}
                    sxProps={{ m: 0, width: "100%" }}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="interestCriteria.business_units"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerBusinessUnits"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Business Unit"}
                    value={businessUnits || []}
                    items={businessUnitsSelection}
                    onChange={onBusinessUnitsSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="interestCriteria.finance_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Finance Type"}
                    value={financetype || []}
                    items={financeTypeSelection}
                    onChange={onFinanceTypeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="interestCriteria.customer_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Customer Type"}
                    value={customertype || []}
                    items={customerTypeSelection}
                    onChange={onCustomerTypeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="interestCriteria.asset_category"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Asset Category"}
                    value={assetcategory || []}
                    items={assetCategorySelection}
                    onChange={onAssetCategorySelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="interestCriteria.asset_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Asset Type"}
                    value={assettype || []}
                    items={assetTypeSelection}
                    onChange={onAssetTypeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="interestCriteria.product_type"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Product Type"}
                    value={productType || []}
                    items={productTypeSelection}
                    onChange={onProductTypeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="interestCriteria.business_trading_age"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    label={"Business Trading Age"}
                    value={tradingage || []}
                    items={tradingAgeSelection}
                    onChange={onTradingAgeSelection}
                    defaultValue={[""]}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="interestCriteria.down_payment_range"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    onChange={onDownPaymentRangeSelection}
                    defaultValue={[""]}
                    sxProps={{ m: 0, width: "100%" }}
                    theme={theme}
                    value={downPaymentRange || []}
                    label={"Down Payment Range"}
                    items={downPaymentRangeSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <Box theme={theme} className="divider-with-text">
              <Box theme={theme} className="divider-text">
                OUTPUT PARAMETERS
              </Box>
            </Box>
            <Controller
              name="interestCriteria.margin_type"
              control={control}
              defaultValue={0}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerMarginType"
                  theme={theme}
                  label={"Margin Type"}
                  items={[{ text: "Rate", value: 0 }]}
                  value={value}
                  onChange={(e) => {
                    const selectedValue = Number(e.target.value);
                    onChange(selectedValue);
                    if (selectedValue === 0) {
                    }
                  }}
                  disabled={typeEnable}
                  selectError={
                    errors?.interestCriteria?.margin_type?.message as never
                  }
                ></Select>
              )}
            />
            <Controller
              name="interestCriteria.margin"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  theme={theme}
                  id="drawerMargin"
                  label={"Margin"}
                  type="text"
                  {...field}
                  onChange={(val) => {
                    if (parseFloat(val) <= 100) setMarginValue(val);
                    else setMarginValue(val.substr(0, 3));
                  }}
                  adornmentOnFocus
                  endAdornment={
                    <>
                      <span className="MuiInputAdornment-root">%</span>
                    </>
                  }
                  disabled={criteriaEnable}
                  masking
                  maskDecimalScale={4}
                  maskNumeric
                  maskAllowNegative
                  // scale={4}
                  // numeric
                  error={errors?.interestCriteria?.margin?.message}
                />
              )}
            />
            <Grid theme={theme} container rowSpacing={3} columnSpacing={2}>
              <Grid theme={theme} item xs={6}>
                <Controller
                  name="interestCriteria.minimum_margin"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      id="drawerMinMargin"
                      type="text"
                      {...field}
                      onChange={(val) => {
                        if (parseFloat(val) <= 100) setMinMargin(val);
                        else setMinMargin(val.substr(0, 3));
                      }}
                      label={"Min. Margin"}
                      adornmentOnFocus
                      endAdornment={
                        <>
                          <span className="MuiInputAdornment-root">%</span>
                        </>
                      }
                      masking
                      maskDecimalScale={4}
                      maskNumeric
                      maskAllowNegative
                      // scale={4}
                      // numeric
                      disabled={criteriaEnable}
                      error={
                        errors?.interestCriteria?.minimum_margin
                          ?.message as never
                      }
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Controller
                  name="interestCriteria.maximum_margin"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      id="drawerMaxMargin"
                      type="text"
                      {...field}
                      onChange={(val) => {
                        if (parseFloat(val) <= 100) setMaxMargin(val);
                        else setMaxMargin(val.substr(0, 3));
                      }}
                      adornmentOnFocus
                      endAdornment={
                        <>
                          <span className="MuiInputAdornment-root">%</span>
                        </>
                      }
                      label={"Max. Margin"}
                      disabled={criteriaEnable}
                      masking
                      maskDecimalScale={4}
                      maskNumeric
                      maskAllowNegative
                      // scale={4}
                      // numeric
                      error={
                        errors?.interestCriteria?.maximum_margin
                          ?.message as never
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
        }
        customFooter={
          <Button
            theme={theme}
            id="saveCriteria"
            primary
            text={"Save"}
            fullWidth={true}
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};
export default NewInterestCharts;
