import * as React from "react";
import { useEffect, useState, FC } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormHelperText, styled, SxProps, Theme } from "@mui/material";
import {
  ISelect,
  Checkbox,
  unityLightTheme,
  unityTheme,
} from "@ntpkunity/controls";
import { StyleVariables } from "../../styles/theme";
// import { ChevronDown } from '@ntpkunity/controls'
import clsx from "clsx";

const ChevronDown: FC<{
  className?: string;
  onClick?: (e: unknown) => void;
}> = ({ onClick, className }) => (
  <svg
    className={className}
    onClick={onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9L12 15L18 9"
      stroke="#9A9EB2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CustomizedSelectWrapper = styled(
  "div",
  {}
)<Partial<ISelect>>(({ theme }) => ({
  "&.u-form-group": {
    marginBottom: 24,
    "&:last-child": {
      marginBottom: 0,
    },
    ".u-form-control": {
      height: 48,
      color: "rgba(0,0,0,0.87)",
      fontFamily: theme.typography.fontFamily,
      "&.u-select": {
        ".MuiInputBase-input": {
          padding: "12px 40px 12px 16px",
        },
        ".MuiSelect-icon": {
          position: "absolute",
          top: "auto",
          right: 12,
          cursor: "pointer",
          userSelect: "none",
          PointerEvent: "none",
          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },
      },
      "&:hover": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
      },
      "&.Mui-focused": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
      },
      "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.38)",
        ".MuiInputBase-input": {
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.38)",
            textFillColor: "rgba(0, 0, 0, 0.38)",
            cursor: "not-allowed",
          },
        },
        fieldset: {
          borderColor: "rgba(0, 0, 0, 0.12)",
        },
        "+": {
          ".u-input-label": {
            color: "rgba(0, 0, 0, 0.38)",
            "+": {
              ".u-help-text": {
                color: "rgba(0, 0, 0, 0.38)",
              },
            },
          },
        },
      },
      "&.MuiInputBase-adornedStart": {
        ".MuiInputBase-input": {
          padding: "12px 40px 12px 0px",
        },
        fieldset: {
          legend: {
            maxWidth: 0,
          },
        },
        "&.Mui-focused": {
          fieldset: {
            legend: {
              maxWidth: "100%",
            },
          },
        },
        "~": {
          ".u-input-label": {
            transform: "translate(38px, 12px) scale(1)",
            "&.Mui-focused": {
              transform: "translate(14px, -9px) scale(0.85)",
            },
            "&.MuiFormLabel-filled": {
              transform: "translate(14px, -9px) scale(0.85)",
            },
          },
        },
      },
      "&.MuiInputBase-adornedEnd": {
        ".MuiInputBase-input": {
          padding: "12px 0px 12px 16px",
        },
      },
      "&.Mui-error": {
        fieldset: {
          borderWidth: 2,
          borderColor: theme.palette.error.main,
        },
        "&:hover": {
          fieldset: {
            borderColor: theme.palette.error.main,
          },
        },
        "~": {
          ".u-input-label": {
            color: theme.palette.error.main,
            "&.Mui-focused": {
              color: theme.palette.error.main,
            },
            "&.MuiFormLabel-filled": {
              color: theme.palette.error.main,
            },
          },
        },
      },
    },
    ".u-input-label": {
      color: "rgba(0,0,0,0.87)",
      lineHeight: theme.typography.body1.lineHeight,
      transform: "translate(14px, 12px) scale(1)",
      padding: "0 5px 0 0px",
      backgroundColor: theme.palette.common.white,
      "&.Mui-focused": {
        color: theme.palette.text.secondary,
        transform: "translate(14px, -9px) scale(0.85)",
        maxWidth: "100%",
      },
      "&.MuiFormLabel-filled": {
        color: theme.palette.text.secondary,
        transform: "translate(14px, -9px) scale(0.85)",
        maxWidth: "100%",
      },
    },
    ".u-help-text": {
      marginLeft: 15,
      lineHeight: theme.typography.caption.lineHeight,
    },
    ".u-error-text": {
      color: theme.palette.error.main,
      marginLeft: 15,
      lineHeight: theme.typography.caption.lineHeight,
    },
    ".text-capitalize": {
      textTransform: "capitalize",
    },
    ".text-uppercase": {
      textTransform: "uppercase",
    },
    ".MuiPopover-root": {
      ".MuiPaper-root": {
        backgroundColor:
          theme.palette.mode === "light"
            ? [theme.palette.common.white]
            : StyleVariables.grey800,
        borderColor:
          theme.palette.mode === "light"
            ? [theme.palette.grey[50]]
            : StyleVariables.grey900,
        backgroundImage: "none",
        ".MuiMenu-list": {
          ".MuiMenuItem-root": {
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? [theme.customVariables.tableStripedBg]
                  : unityLightTheme.palette.grey[700],
              ".MuiFormGroup-root": {
                ".MuiFormControlLabel-root": {
                  ".MuiTypography-root": {
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.grey[900]
                        : theme.palette.common.white,
                  },
                },
              },
            },
            "&.Mui-selected": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? "#F2F4FA"
                  : theme.palette.grey[100],
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[900]
                  : [theme.palette.common.white],
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? "#F2F4FA"
                    : theme.palette.grey[100],
              },
              "&.Mui-focusVisible": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? "#F2F4FA"
                    : theme.palette.grey[100],
              },
            },
            ".custom-checkbox-wrap": {
              ".u-custom-control-label": {
                marginBottom: 0,
              },
            },
          },
        },
      },
    },
  },
}));

const MenuSx = (theme: Theme): SxProps => ({
  borderStyle: "solid",
  borderWidth: 1,
  backgroundColor:
    theme.palette.mode === "light"
      ? [theme.palette.common.white]
      : StyleVariables.grey800,
  borderColor:
    theme.palette.mode === "light"
      ? [theme.palette.grey[50]]
      : StyleVariables.grey900,
  backgroundImage: "none",
  boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
  padding: "0",
  color: "#515466",
  maxHeight: 250,
  "@supports (-moz-appearance:none)": {
    scrollbarWidth: "thin",
  },
  "&::-webkit-scrollbar": {
    width: 7,
    height: 7,
    borderRadius: 8,
  },
  "&::-webkit-scrollbar-track": {
    padding: "0 1px",
    backgroundColor:
      theme.palette.mode === "light"
        ? "#f0f0f0"
        : unityLightTheme.palette.grey[700],
    borderRadius: 8,
  },
  "&::-webkit-scrollbar-thumb": {
    transition: "all 0.3s",
    backgroundColor:
      theme.palette.mode === "light"
        ? "#cdcdcd"
        : unityLightTheme.palette.grey[600],
    borderRadius: 8,
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor:
      theme.palette.mode === "light"
        ? "#a6a6a6"
        : unityLightTheme.palette.grey[500],
  },
  ".MuiMenu-list": {
    padding: "8px 0",
  },
  ".u-dropdown-item": {
    padding: "12px 24px 12px 16px",
    borderRadius: "8px",
    whiteSpace: "normal",
    margin: "0 8px",
    fontFamily: theme.typography.fontFamily,
    color:
      theme.palette.mode === "light"
        ? theme.palette.grey[900]
        : theme.palette.grey[200],
    "&:hover, &.Mui-focusVisible": {
      backgroundColor:
        theme.palette.mode === "light"
          ? [theme.customVariables.tableStripedBg]
          : unityLightTheme.palette.grey[700],
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[900]
          : theme.palette.common.white,
    },
    "&.Mui-focusVisible": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[900]
          : theme.palette.grey[200],
    },
    "&.Mui-selected": {
      backgroundColor:
        theme.palette.mode === "light" ? "#F2F4FA" : theme.palette.grey[100],
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[900]
          : [theme.palette.common.white],
      "&:hover": {
        backgroundColor:
          theme.palette.mode === "light" ? "#F2F4FA" : theme.palette.grey[100],
      },
      "&.Mui-focusVisible": {
        backgroundColor:
          theme.palette.mode === "light" ? "#F2F4FA" : theme.palette.grey[100],
      },
    },
    ".MuiTouchRipple-root": {
      display: "none",
    },
    ".custom-checkbox-wrap": {
      ".u-custom-control-label": {
        margin: 0,
        display: "inline-block",
        width: "100%",
        ".custom-checkbox": {
          color: theme.palette.grey[300],
          marginRight: 0,
          position: "absolute",
          width: "97%",
          textAlign: "left",
          display: "inline-block",
          "&.Mui-checked ~ .MuiFormControlLabel-label": {
            color:
              theme.palette.mode === "dark"
                ? theme.palette.common.white + "!important"
                : theme.palette.common.black + "!important",
          },
        },
        ".MuiFormControlLabel-label": {
          marginLeft: "30px",
          paddingTop: "24px",
          color: theme.palette.grey[600],
        },
      },
    },
  },
});

const MultiSelect: React.FC<any> = ({
  theme,
  onChange: onChangeProps,
  name,
  items = [],
  label,
  value = [],
  helperText = "",
  disabled = false,
  id = "select-simple",
  capitalizeLabel,
  uppercaseLabel,
  selectError,
  defaultValue,
  disablePortal = true,
}) => {
  const [selectedValues, setSelectedValues] = React.useState<any[]>(
    value as any[]
  );

  useEffect(() => {
    if (value?.length === items?.length) {
      const allValues = items?.map((item) => item?.value);
      setSelectedValues(allValues);
    } else {
      setSelectedValues(Array.isArray(value) ? value : []);
    }
  }, [items]);

  const handleItemChange = (event) => {
    const value = event.target.value;
    if (selectedValues?.includes(value)) {
      setSelectedValues(selectedValues?.filter((item) => item !== value));
      onChangeProps?.(
        event,
        selectedValues?.filter((item) => item !== value)
      );
    } else {
      const newValue = typeof value === "string" ? value.split(",") : value;
      setSelectedValues(newValue);
      onChangeProps?.(event, newValue);
    }
  };

  const handleSelectAll = (event) => {
    const allSelected = event.target.checked;
    const allValues = items.map((item) => item.value);
    if (allSelected) {
      setSelectedValues(allValues);
      onChangeProps?.(allValues);
    } else {
      setSelectedValues([]);
      onChangeProps?.([]);
    }
  };

  return (
    <CustomizedSelectWrapper theme={theme} className="u-form-group">
      <FormControl fullWidth>
        <Select
          MenuProps={{
            disablePortal,
            PaperProps: {
              sx: MenuSx(theme),
            },
          }}
          inputProps={{
            name: [name],
          }}
          disabled={disabled}
          className="u-form-control u-select"
          labelId="demo-simple-select-label"
          id={id}
          multiple
          label={label}
          value={value ?? []}
          error={!!selectError}
          onChange={handleItemChange}
          defaultValue={defaultValue}
          renderValue={(selected: any[]) =>
            selected && selected.length
              ? selected?.length === items?.length
                ? "All"
                : items
                    ?.filter((item) => selected.includes(item.value))
                    ?.map((item) => item.text)
                    ?.join(", ")
              : ""
          }
          sx={{ borderRadius: "8px" }}
          IconComponent={ChevronDown}
        >
          <MenuItem
            className="u-dropdown-item u-dropdown-item-multi"
            key="select-all"
          >
            <Checkbox
              checkBoxChecked={selectedValues?.length === items.length}
              label={"All"}
              onChange={(event) => handleSelectAll(event)}
            />
          </MenuItem>
          {items.map((item) => (
            <MenuItem
              className="u-dropdown-item u-dropdown-item-multi"
              key={item.value}
              value={item.value}
            >
              <Checkbox
                checkBoxChecked={selectedValues?.includes(item.value)}
                label={item.text}
              />
            </MenuItem>
          ))}
        </Select>
        <InputLabel
          className={clsx({
            "u-input-label": true,
            "text-capitalize": capitalizeLabel,
            "text-uppercase": uppercaseLabel,
          })}
          id="demo-simple-select-label"
        >
          {label}
        </InputLabel>
        {selectError && (
          <FormHelperText className="u-error-text" id={id}>
            {selectError}
          </FormHelperText>
        )}
        <FormHelperText className="u-help-text">{helperText}</FormHelperText>
      </FormControl>
    </CustomizedSelectWrapper>
  );
};

export default MultiSelect;
