import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { AddNewOptionCategory } from "components";
import { IOptionCategory } from "Interface";
import OptionCategoryTable from "./option-category-table";
import { ActionOptions } from "Enums";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";

const OptionCategory = () => {
  const [optionCategoryState, setOptionCategoryState] =
    useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>(
    "Add New Add-On Category"
  );
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );

  const [actionData, setActionData] = useState<IOptionCategory>(undefined);

  const [state, dispatch] = useSetupsStore();

  const theme = useTheme();

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Add-On Category");
        setActionData(value);
        setOptionCategoryState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  useEffect(() => {
    if (optionCategoryState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [optionCategoryState]);
  const onEdit = (formData: IOptionCategory) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Add-On Category");
    setActionData(formData);
    setOptionCategoryState(true);
    dispatch(setOpenDrawer(true));
  };
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button" key={1}>
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <OptionCategoryTable onEdit={(data) => onEdit(data)} />
        <Button
          id="addNewOptionCategory"
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Add-On Category"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        ></Button>
        {optionCategoryState && (
          <AddNewOptionCategory
            openPopUp={optionCategoryState}
            setPopUpState={setOptionCategoryState}
            title={actionTitle}
            actionType={actionType}
            optionCategoryData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};

export default OptionCategory;
