import { FC, useEffect, useState, useMemo } from "react";
import { useHasPermissions, indexPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "Enums";
import { useNavigate } from "react-router-dom";
import { Validate } from "@ntpkunity/controls-ums";
import { Company, DealerConfigurations } from "pages";
import { APP_ROUTES } from "shared/config/links";

const DefaultRoute: FC = () => {
  const viewLenderProfile = useHasPermissions(
    indexPermissions?.ROUTING?.LENDER_PROFILE,
    PermissionTypes.VIEW
  );
  const viewDealerProfile = useHasPermissions(
    indexPermissions?.ROUTING?.DEALER_CONFIGURATIONS,
    PermissionTypes.VIEW
  );
  const [viewDealerProfilePermission, setViewDealerProfilePermission] =
    useState(false);
  const [viewLenderProfilePermission, setViewLenderProfilePermission] =
    useState(false);
  const [lenderRole, setLenderRole] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setViewDealerProfilePermission(viewDealerProfile);
  }, [viewDealerProfile]);
  useEffect(() => {
    setViewLenderProfilePermission(viewLenderProfile);
  }, [viewLenderProfile]);
  useEffect(() => {
    if (viewDealerProfilePermission && !viewLenderProfilePermission) {
      <Validate
            permission={indexPermissions.ROUTING.DEALER_CONFIGURATIONS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
              <DealerConfigurations />
          </Validate>
    } else {
      setLenderRole(true);
    }
  }, [viewDealerProfilePermission, viewLenderProfilePermission]);
  return lenderRole ? 
  <Validate 
    permission={indexPermissions.ROUTING.LENDER_PROFILE}
    url={APP_ROUTES.COMPANY_PROFILE}>
    <Company />
    </Validate> : <></>;
};

export default DefaultRoute;
