import React, { FC, useRef } from "react";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { Box, DataTable, Input, Icon, Tooltip } from "@ntpkunity/controls";
import { Controller } from "react-hook-form";
import { BorderedTableWrap } from "@sharedComponents";
import { MultiSelectWrap } from "@sharedComponents";
import { Select } from "@ntpkunity/controls";
import { ValidationMessages } from "Enums";

interface IFinanceTypeMappingProps {
  form: any;
  setUpdatedIntroducerRows: any;
  introducerMappings: any;
  showDefaultError: string;
}

const IntroducerMappingTable: FC<IFinanceTypeMappingProps> = ({
  form,
  setUpdatedIntroducerRows,
  introducerMappings,
  showDefaultError,
}) => {
  const theme = useTheme();
  const {
    control,
    formState: { errors },
  } = form;

  const lastRowRef = useRef(null);

  const handleRowChange = (index) => {
    setUpdatedIntroducerRows((prev) => new Set(prev).add(index));
  };

  return (
    <BorderedTableWrap theme={theme} className="custom-table-wrap">
      <Box theme={theme} className="scroll">
        <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
      </Box>
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              <TableCell className="sr-num"></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email Address</TableCell>
              <TableCell>Unique ID</TableCell>
              <TableCell>External Code</TableCell>
              <TableCell
                className={`input-cell ${showDefaultError ? "error" : ""}`}
              >
                <Box theme={theme} className="error-label">
                  Default
                </Box>{" "}
                {showDefaultError && (
                  <Tooltip
                    theme={theme}
                    title={showDefaultError}
                    placement="left"
                  >
                    <Icon name="CircleExc" />
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={introducerMappings?.map((item, index) => (
          <React.Fragment key={item.id || index}>
            <TableRow
              className="child-tr"
              ref={index === introducerMappings.length - 1 ? lastRowRef : null}
            >
              <TableCell className="sr-num">{index + 1}</TableCell>
              <Controller
                name={`introducerMapping.${index}.id`}
                control={control}
                defaultValue={item.id}
                render={({ field }) => <input type="hidden" {...field} />}
              />
              <TableCell className="input-cell">
                <Controller
                  name={`introducerMapping.${index}.name`}
                  control={control}
                  defaultValue={item.name || ""}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Type here..."}
                      type="string"
                      id={`introducerMapping.${index}.name`}
                      onChange={(newValue) => {
                        onChange(newValue);
                        handleRowChange(index);
                      }}
                      value={value?.trimStart()}
                    ></Input>
                  )}
                />
              </TableCell>
              <TableCell
                className={`input-cell ${
                  errors?.introducerMapping?.[index]?.email_address
                    ? "error"
                    : ""
                }`}
              >
                {errors?.introducerMapping?.[index]?.email_address && (
                  <Tooltip
                    theme={theme}
                    title={
                      errors?.introducerMapping?.[index]?.email_address
                        ?.message || ""
                    }
                    placement="top"
                  >
                    <Icon name="CircleExc" />
                  </Tooltip>
                )}
                <Controller
                  name={`introducerMapping.${index}.email_address`}
                  control={control}
                  defaultValue={item.email_address || ""}
                  rules={{
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: ValidationMessages.INVALID_EMAIL,
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"sample@example.com"}
                      type="email"
                      id={`introducerMapping.${index}.email_address`}
                      onChange={(newValue) => {
                        onChange(newValue);
                        handleRowChange(index);
                      }}
                      value={value?.trimStart()}
                    ></Input>
                  )}
                />
              </TableCell>
              <TableCell className="input-cell">
                <Controller
                  name={`introducerMapping.${index}.unique_id`}
                  control={control}
                  defaultValue={item.unique_id || ""}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Type here..."}
                      type="string"
                      id={`introducerMapping.${index}.unique_id`}
                      onChange={(newValue) => {
                        onChange(newValue);
                        handleRowChange(index);
                      }}
                      value={value?.trimStart()}
                    ></Input>
                  )}
                />
              </TableCell>
              <TableCell className="input-cell">
                <Controller
                  name={`introducerMapping.${index}.external_code`}
                  control={control}
                  defaultValue={item.external_code || ""}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Type here..."}
                      type="string"
                      id={`introducerMapping.${index}.external_code`}
                      onChange={(newValue) => {
                        onChange(newValue);
                        handleRowChange(index);
                      }}
                      value={value?.trimStart()}
                    ></Input>
                  )}
                />
              </TableCell>
              <TableCell
                className={`input-cell ${
                  errors?.introducerMapping?.[index]?.is_default ? "error" : ""
                }`}
              >
                {errors?.introducerMapping?.[index]?.is_default && (
                  <Tooltip
                    theme={theme}
                    title={
                      errors?.introducerMapping?.[index]?.is_default?.message ||
                      ""
                    }
                    placement="left"
                  >
                    <Icon name="CircleExc" />
                  </Tooltip>
                )}
                <MultiSelectWrap>
                  <Controller
                    name={`introducerMapping.${index}.is_default`}
                    control={control}
                    defaultValue={item.is_default}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        theme={theme}
                        fullWidth={true}
                        disablePortal={false}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        type="string"
                        id={`introducerMapping.${index}.is_default`}
                        items={[
                          { text: "Yes", value: true },
                          { text: "No", value: false },
                        ]}
                        value={value}
                        onChange={(newValue) => {
                          onChange(newValue);
                          handleRowChange(index);
                        }}
                      />
                    )}
                  />
                </MultiSelectWrap>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      />
    </BorderedTableWrap>
  );
};

export default IntroducerMappingTable;
