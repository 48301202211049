import { FC, useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import {
  unityTheme,
  Select,
  IBoxProps,
  MultiSelect,
  PhoneInput,
  Icon,
} from "@ntpkunity/controls";
import {
  Grid,
  Input,
  Box,
  Typography,
  AddressLayout,
  MultiSelectWrap,
  MultiSelectAll,
} from "@sharedComponents";
import { useHasPermissions, indexPermissions } from "@ntpkunity/controls-ums";
import {
  UseQuery_GetAllFinanceTypes,
  UseQuery_GetAllAssetCategory,
  UseQuery_GetAllAssetType,
  UseQuery_GetAllDealTypes,
} from "services";
import { styled } from "@mui/material/styles";
import { validation } from "shared/helper/methods";
import { useTheme } from "@mui/material";
import { UseQuery_getAllStates } from "services/dealer-configurations.service";
import { useQueryClient } from "react-query";
import { QueryKeys, ValidationMessages } from "Enums";
import {
  ICountry,
  IFinanceTypeModel,
  IAssetCondition,
  IAssetCategory,
  IAssetType,
} from "Interface";
const TabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  "&.tab-wrap": {
    width: "100%",
    display: "inline-block",

    hr: {
      opacity: 0.4,
    },

    ".title-icon": {
      width: "100%",
      display: "inline-block",
      "span, Icon": {
        float: "left",
      },
      span: {
        marginRight: 10,
      },
    },
    ".buttons-row": {
      display: "inline-block",
      textAlign: "center",
      width: "100%",

      Button: {
        margin: "0 20px 20px 0",

        "&:last-of-type": {
          marginRight: 0,
        },
      },
    },
    ".label-shrink": {
      ".u-form-group": {
        ".MuiFormControl-root": {
          ".MuiInputLabel-root": {
            transform: "translate(14px, -9px) scale(0.85)",

            color: "rgba(0, 0, 0, 0.6)",

            padding: "0 5px 0 5px",

            left: -4,

            top: 0,

            zIndex: 0,
          },
        },
      },
    },
  },
}));
const Schedule_option = [
  { text: "Pickup", value: "pickup" },
  { text: "Delivery", value: "delivery" },
];
const day_light = [
  { text: "Yes", value: true },
  { text: "No", value: false },
];
const timeZone = [
  {
    text: "(GMT-12:00) Baker Island Time",
    value: "(GMT-12:00) Baker Island Time",
  },
  {
    text: "(GMT-11:00) Niue Time",
    value: "(GMT-11:00) Niue Time",
  },
  {
    text: "(GMT-11:00) Samoa Standard Time (SST)",
    value: "(GMT-11:00) Samoa Standard Time (SST)",
  },
  {
    text: "(GMT-10:00) Hawaii-Aleutian Standard Time (HAST)",
    value: "(GMT-10:00) Hawaii-Aleutian Standard Time (HAST)",
  },
  {
    text: "(GMT-09:00) Alaska Standard Time (AKST)",
    value: "(GMT-09:00) Alaska Standard Time (AKST)",
  },
  {
    text: "(GMT-08:00) Pacific Standard Time (PST)",
    value: "(GMT-08:00) Pacific Standard Time (PST)",
  },
  {
    text: "(GMT-07:00) Mountain Standard Time (MST)",
    value: "(GMT-07:00) Mountain Standard Time (MST)",
  },
  {
    text: "(GMT-06:00) Central Standard Time (CST)",
    value: "(GMT-06:00) Central Standard Time (CST)",
  },
  {
    text: "(GMT-05:00) Eastern Standard Time (EST)",
    value: "(GMT-05:00) Eastern Standard Time (EST)",
  },
  {
    text: "(GMT-04:00) Atlantic Standard Time (AST)",
    value: "(GMT-04:00) Atlantic Standard Time (AST)",
  },
  {
    text: "(GMT-03:00) Brasília Time (BRT)",
    value: "(GMT-03:00) Brasília Time (BRT)",
  },
  {
    text: "(GMT-02:00) South Georgia Time",
    value: "(GMT-02:00) South Georgia Time",
  },
  {
    text: "(GMT-01:00) Azores Standard Time",
    value: "(GMT-01:00) Azores Standard Time",
  },
  {
    text: "(GMT+00:00) Coordinated Universal Time (UTC)",
    value: "(GMT+00:00) Coordinated Universal Time (UTC)",
  },
  {
    text: "(GMT+01:00) Central European Time (CET)",
    value: "(GMT+01:00) Central European Time (CET)",
  },
  {
    text: "(GMT+02:00) Eastern European Time (EET)",
    value: "(GMT+02:00) Eastern European Time (EET)",
  },
  {
    text: "(GMT+03:00) Moscow Standard Time (MSK)",
    value: "(GMT+03:00) Moscow Standard Time (MSK)",
  },
  {
    text: "(GMT+04:00) Gulf Standard Time",
    value: "(GMT+04:00) Gulf Standard Time",
  },
  {
    text: "(GMT+05:00) Pakistan Standard Time",
    value: "(GMT+05:00) Pakistan Standard Time",
  },
  {
    text: "(GMT+06:00) Bangladesh Standard Time",
    value: "(GMT+06:00) Bangladesh Standard Time",
  },
  {
    text: "(GMT+07:00) Indochina Time",
    value: "(GMT+07:00) Indochina Time",
  },
  {
    text: "(GMT+08:00) China Standard Time (CST)",
    value: "(GMT+08:00) China Standard Time (CST)",
  },
  {
    text: "(GMT+09:00) Japan Standard Time (JST)",
    value: "(GMT+09:00) Japan Standard Time (JST)",
  },
  {
    text: "(GMT+10:00) Australian Eastern Standard Time (AEST)",
    value: "(GMT+10:00) Australian Eastern Standard Time (AEST)",
  },
  {
    text: "(GMT+10:00) Chamorro Standard Time (ChST)",
    value: "(GMT+10:00) Chamorro Standard Time (ChST)",
  },
  {
    text: "(GMT+11:00) Magadan Time",
    value: "(GMT+11:00) Magadan Time",
  },
  {
    text: "(GMT+12:00) Fiji Time",
    value: "(GMT+12:00) Fiji Time",
  },
];

const General: FC<{
  form: any;
  dealer_code: string;
  companyCountries: any;
  setIsAddressDirty?: any;
  isAddressDirty?: boolean;
  isAddressValidationActive?: boolean;
  providerByCompany?: boolean;
}> = ({
  form,
  dealer_code,
  companyCountries,
  setIsAddressDirty,
  isAddressDirty,
  isAddressValidationActive,
  providerByCompany,
}) => {
  const queryClient = useQueryClient();
  const theme = useTheme();

  UseQuery_getAllStates();
  UseQuery_GetAllFinanceTypes();
  UseQuery_GetAllAssetCategory();
  UseQuery_GetAllAssetType();
  UseQuery_GetAllDealTypes();

  const available_finance: Array<IFinanceTypeModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCE_TYPES
  );
  const dealTypes: { dealTypeData: Array<any> } = queryClient.getQueryData(
    QueryKeys.DEAL_TYPES
  );
  const availableAssetCategories: Array<IAssetCategory> =
    queryClient.getQueryData(QueryKeys.Get_All_ASSET_CATEGORY);

  const availableAssetTypes: Array<IAssetType> = queryClient.getQueryData(
    QueryKeys.Get_All_ASSET_TYPE
  );

  const AssetCondition: Array<IAssetCondition> = queryClient.getQueryData(
    QueryKeys.Get_All_Asset_Conditions
  );

  const StatesData: any = queryClient.getQueryData(QueryKeys.GET_ALL_STATES);
  const {
    control,
    formState: { isDirty, errors },
    setValue,
    trigger,
    watch,
    getValues,
  } = form;
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({
    id: null,
    code: "",
    name: "",
    country_code: "",
    address_template: null,
  });
  const country = watch("profile.dealer_address.country_id");
  const selectedDealType = watch("profile.taxable_deal_types");
  useEffect(() => {
    if (country && companyCountries) {
      let foundCountry: ICountry = companyCountries?.find(
        (x) => x?.id == country?.id
      );
      if (foundCountry) {
        setSelectedCountry(foundCountry);
        setValue("profile.country_code", foundCountry?.country_code);
        setValue(
          "profile.dealer_address.apply_zip_code_validation",
          foundCountry?.address_template?.field3_visible
        );
      }
    }
  }, [country]);
  function handleCountryChange() {
    setValue("profile.contact_number", "");
    setValue("profile.customer_support_number", "");
    setValue("profile.fax_number", "");
    setValue("profile.dealer_address.verified", false);
  }

  const filterAssetItems = <T extends Record<string, any>>(
    items: Set<string>,
    arr: T[],
    checkProperty: keyof T,
    getProperty: keyof T
  ) => {
    return arr?.reduce((acc, item) => {
      if (items.has(item[checkProperty])) {
        acc = [...acc, item[getProperty].toString()];
        return acc;
      }
      return acc;
    }, []);
  };

  const filterAssetTypes = (selectedCategories: string[]) => {
    const selectedCategoriesSet = new Set(selectedCategories);
    const filteredCategoriesIds = filterAssetItems<IAssetCategory>(
      selectedCategoriesSet,
      availableAssetCategories,
      "description",
      "id"
    );
    return availableAssetTypes?.filter((value) =>
      filteredCategoriesIds?.includes(value.asset_category_id)
    );
  };

  const assetCategories = watch("profile.available_asset_categories");

  const filteredAssetTypes = filterAssetTypes(assetCategories);

  const assetTypes: string[] = watch("profile.available_asset_types");
  const filterSelectedAssetTypes = (selectedCategories: string[]) => {
    const assetTypesSet = new Set(assetTypes);
    const filteredTypesName = filterAssetItems<IAssetType>(
      assetTypesSet,
      filterAssetTypes(selectedCategories),
      "name",
      "name"
    );
    setValue("profile.available_asset_types", filteredTypesName);
  };

  const getAllActiveAssetConditions = () => {
    return (
      AssetCondition?.filter((x) => x.is_active).map((asset) => ({
        value: asset.description,
        text: `${asset.description}`,
      })) || []
    );
  };

  const getAllAssetConditionValues = () => {
    return setValue(
      "profile.vehicle_condition",
      getAllActiveAssetConditions().map((asset) => asset.value) || []
    );
  };
  const getAllConfiguredDealTypes = () => {
    return (
      dealTypes?.dealTypeData?.map((item) => {
        return {
          text: item.deal_type_name,
          value: item.deal_type_name,
        };
      }) || []
    );
  };
  const getAllDealTypesValues = () => {
    return setValue(
      "profile.taxable_deal_types",
      getAllConfiguredDealTypes().map((item) => item.value) || []
    );
  };
  return (
    <TabWrap className="tab-wrap">
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mb: 2.7 }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MultiSelectWrap>
            <Controller
              name="profile.taxable_deal_types"
              control={control}
              rules={{
                required: ValidationMessages.APPLICABLE_DEAL_TYPE_REQUIRED,
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <MultiSelectAll
                    disablePortal
                    id={"taxable_deal_types"}
                    theme={theme}
                    label={"Available Deal Types"}
                    placeholder={""}
                    items={getAllConfiguredDealTypes()}
                    value={value ? value : getAllDealTypesValues()}
                    onChange={(e, value) => {
                      onChange(value);
                      if (
                        Array.isArray(value) &&
                        !value?.includes("Financing")
                      ) {
                        setValue("profile.available_finance_types", []);
                      }
                    }}
                    selectError={errors?.profile?.taxable_deal_types?.message}
                  />
                );
              }}
            />
          </MultiSelectWrap>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MultiSelectWrap>
            <Controller
              name="profile.available_finance_types"
              control={control}
              rules={validation("Available Finance Type", true)}
              render={({ field: { value, onChange } }) => (
                <MultiSelectAll
                  disablePortal
                  id={"available_finance_type"}
                  theme={theme}
                  label={"Available Finance Types"}
                  placeholder={""}
                  items={
                    watch("profile.taxable_deal_types")?.includes("Financing")
                      ? available_finance?.map((item) => {
                          return { text: item.name, value: item.name };
                        })
                      : []
                  }
                  value={value ?? []}
                  onChange={(e, value) => {
                    onChange(value);
                  }}
                  selectError={
                    errors?.profile?.available_finance_types?.message
                  }
                  disabled={!selectedDealType?.includes("Financing")}
                  sxProps={""}
                />
              )}
            />
          </MultiSelectWrap>
        </Grid>

        {useHasPermissions(
          indexPermissions.FIELDS.AVAILABLE_ASSET_CATEGORIES
        ) ? (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <MultiSelectWrap>
              <Controller
                name="profile.available_asset_categories"
                control={control}
                rules={validation("Available Asset Category", true)}
                render={({ field: { value, onChange } }) => (
                  <MultiSelect
                    disablePortal
                    id={"available_asset_category"}
                    theme={theme}
                    label={"Available Asset Category"}
                    placeholder={""}
                    items={availableAssetCategories?.map((item) => {
                      return {
                        text: item.description,
                        value: item.description,
                      };
                    })}
                    value={value ?? []}
                    onChange={(e: any) => {
                      const selectedCategories = e.target.value;
                      onChange(selectedCategories);
                      filterSelectedAssetTypes(selectedCategories);
                    }}
                    selectError={
                      errors?.profile?.available_asset_categories?.message
                    }
                    sxProps={""}
                  />
                )}
              />
            </MultiSelectWrap>
          </Grid>
        ) : null}
        {useHasPermissions(indexPermissions.FIELDS.AVAILABLE_ASSET_TYPES) ? (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <MultiSelectWrap>
              <Controller
                name="profile.available_asset_types"
                control={control}
                rules={validation("Available Asset Types", true)}
                render={({ field: { value, onChange } }) => (
                  <MultiSelect
                    disablePortal
                    id={"available_asset_type"}
                    theme={theme}
                    label={"Available Asset Type"}
                    placeholder={""}
                    items={filteredAssetTypes?.map((item) => {
                      return { text: item.name, value: item.name };
                    })}
                    value={value ?? []}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    selectError={
                      errors?.profile?.available_asset_types?.message
                    }
                    sxProps={""}
                  />
                )}
              />
            </MultiSelectWrap>
          </Grid>
        ) : null}
        {useHasPermissions(indexPermissions.FIELDS.SCHEDULE_OPTION) && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <MultiSelectWrap>
              <Controller
                name="profile.schedule_option"
                control={control}
                rules={validation("Schedule Option", true)}
                render={({ field: { value, onChange } }) => (
                  <MultiSelect
                    disablePortal
                    id={"scheduled_options"}
                    theme={theme}
                    label={"Schedule Options"}
                    placeholder={""}
                    items={Schedule_option.map((option) => {
                      return { value: option.value, text: option.text };
                    })}
                    value={value ?? []}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    selectError={errors?.profile?.schedule_option?.message}
                    sxProps={""}
                  />
                )}
              />
            </MultiSelectWrap>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MultiSelectWrap>
            <Controller
              name="profile.vehicle_condition"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <MultiSelect
                    disablePortal
                    id={"vehicle_condition"}
                    name="profile.vehicle_condition"
                    theme={theme}
                    label={"Asset Condition"}
                    placeholder={""}
                    items={getAllActiveAssetConditions()}
                    value={value ? value : getAllAssetConditionValues()}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    selectError={errors?.profile?.vehicle_condition?.message}
                    sxProps={""}
                  />
                );
              }}
            />
          </MultiSelectWrap>
        </Grid>
        {useHasPermissions(indexPermissions.FIELDS.CONTACT_NUMBER) && (
          <Grid item xs={12} sm={6} md={4} lg={3} className="label-shrink">
            <Controller
              name="profile.contact_number"
              control={control}
              rules={validation("Contact Number", true)}
              defaultValue={watch("profile.contact_number")}
              render={({ field }) => (
                <>
                  <PhoneInput
                    theme={unityTheme}
                    type={"text"}
                    {...field}
                    label={"Contact Number"}
                    fullWidth
                    phoneCountryCode={selectedCountry?.country_code ?? "US"}
                    error={errors?.profile?.contact_number?.message}
                  />
                </>
              )}
            />
          </Grid>
        )}
        {useHasPermissions(indexPermissions.FIELDS.CUSTOMER_SUPPORT_NUMBER) && (
          <Grid item xs={12} sm={6} md={4} lg={3} className="label-shrink">
            <Controller
              name="profile.customer_support_number"
              control={control}
              rules={validation("Customer Support Number", true)}
              defaultValue={watch("profile.customer_support_number")}
              render={({ field }) => (
                <PhoneInput
                  theme={unityTheme}
                  type={"text"}
                  {...field}
                  label={"Customer Support Number"}
                  fullWidth
                  phoneCountryCode={selectedCountry?.country_code ?? "US"}
                  error={errors?.profile?.customer_support_number?.message}
                />
              )}
            />
          </Grid>
        )}
        {useHasPermissions(indexPermissions.FIELDS.FAX_NUMBER) && (
          <Grid item xs={12} sm={6} md={4} lg={3} className="label-shrink">
            <Controller
              name="profile.fax_number"
              control={control}
              defaultValue={watch("profile.fax_number")}
              render={({ field }) => (
                <PhoneInput
                  theme={unityTheme}
                  type={"text"}
                  {...field}
                  label={"Fax Number (Optional)"}
                  fullWidth
                  phoneCountryCode={selectedCountry?.country_code ?? "US"}
                />
              )}
            />
          </Grid>
        )}
        {useHasPermissions(indexPermissions.FIELDS.TIME_ZONE) && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controller
              name="profile.time_zone"
              control={control}
              defaultValue={""}
              rules={validation("Time Zone", true)}
              render={({ field }) => (
                <Select
                  disablePortal
                  theme={theme}
                  label={"Time Zone"}
                  items={timeZone}
                  {...field}
                  value={field.value ?? ""}
                  sxProps={""}
                  selectError={errors?.profile?.time_zone?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </Grid>
        )}
        {useHasPermissions(indexPermissions.FIELDS.EMAIL_ADDRESS) && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controller
              name="profile.email"
              control={control}
              rules={validation("Email", true)}
              defaultValue={""}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder={"sample@sample.com"}
                  label={"Email Address"}
                  type="email"
                  {...field}
                  value={field.value ?? ""}
                  error={errors?.profile?.email?.message}
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.website"
            control={control}
            rules={validation("Website", true)}
            defaultValue={""}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"www.sample.com"}
                label={"Website"}
                type="text"
                {...field}
                value={field.value?.trimStart()}
                error={errors?.profile?.website?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.contact_person_name"
            control={control}
            rules={validation("Contact Person Name", true)}
            defaultValue={""}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"Type here.."}
                label={"Contact Person Name"}
                type="text"
                {...field}
                value={field.value?.trimStart()}
                error={errors?.profile?.contact_person_name?.message}
              />
            )}
          />
        </Grid>
        {useHasPermissions(
          indexPermissions.FIELDS.DAYLIGHT_SAVING_APPLICABLE
        ) && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controller
              name="profile.day_light_saving"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  disablePortal
                  theme={theme}
                  label={"Daylight Saving Applicable"}
                  items={day_light}
                  {...field}
                  value={field.value ?? ""}
                  sxProps={""}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  selectError={errors?.profile?.day_light_saving?.message}
                />
              )}
            />
          </Grid>
        )}
        {useHasPermissions(indexPermissions.FIELDS.INITIALS) && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controller
              name="profile.initials"
              control={control}
              rules={validation("Initials", true)}
              defaultValue={""}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder={"Type here.."}
                  label={"Initials"}
                  type="text"
                  value={value?.trimStart()}
                  error={errors?.profile?.initials?.message}
                  onChange={(e) => {
                    onChange(
                      e
                        .replace(/[^a-z]/gi, "")
                        .toUpperCase()
                        .slice(0, 2)
                    );
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.trading_as"
            control={control}
            defaultValue={""}
            render={({ field: { onChange, value } }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"Type here.."}
                label={"Trading As"}
                type="text"
                value={value?.trimStart()}
                onChange={(e) => {
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.contact_person_phone_number"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <PhoneInput
                theme={unityTheme}
                type={"text"}
                {...field}
                label={"Contact Person Phone Number"}
                fullWidth
                phoneCountryCode={selectedCountry?.country_code ?? "US"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.contact_person_email"
            control={control}
            defaultValue={""}
            rules={validation(
              "Contact Person Email",
              false,
              true,
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              "Invalid Email Address"
            )}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"sample@sample.com"}
                label={"Contact Person Email Address"}
                type="email"
                {...field}
                value={field.value?.trimStart()}
                onChange={(e) => {
                  field.onChange(e);
                }}
                error={errors?.profile?.contact_person_email?.message}
              />
            )}
          />
        </Grid>
        {useHasPermissions(indexPermissions.FIELDS.SUB_DOMAIN) && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controller
              name="profile.sub_domain"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder={"sample@sample.com"}
                  label={"Sub Domain"}
                  type="text"
                  {...field}
                  value={field.value?.trimStart()}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  error={errors?.profile?.sub_domain?.message}
                />
              )}
            />
          </Grid>
        )}
        {useHasPermissions(indexPermissions.FIELDS.CRM_EMAILS) && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controller
              name="profile.crm_emails"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder={"sample@sample.com, sample1@sample.com"}
                  label={"CRM Emails"}
                  type="text"
                  {...field}
                  value={
                    Array.isArray(field?.value)
                      ? field?.value?.join(",")
                      : field?.value
                  }
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  onBlur={() => {}}
                  error={errors?.profile?.crm_emails?.message}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Box sx={{ mb: 3 }}>
        <hr />
      </Box>
      <Typography
        className="title-icon"
        variant="h4"
        component="h4"
        theme={theme}
      >
        <span>Dealer Address</span>
        {getValues("profile.dealer_address.verified") &&
          companyCountries?.length > 0 && <Icon name="GreenCheckIcon" />}
      </Typography>
      <Box sx={{ mt: 3, mb: 30 }}>
        <Controller
          name="profile.dealer_address"
          control={control}
          defaultValue={watch("profile.dealer_address")}
          render={({ field }) => (
            <AddressLayout
              theme={theme}
              states={StatesData}
              {...field}
              countries={companyCountries}
              isContactRequired={false}
              value={watch("profile.dealer_address")}
              hasAddressTooltip={true}
              onChange={(e: any) => {
                setValue("profile.dealer_address", e);
                trigger("profile.dealer_address");
              }}
              handleCountryChange={handleCountryChange}
              setIsAddressDirty={setIsAddressDirty}
              validationProps={errors?.profile?.dealer_address}
              disableCounty={isAddressValidationActive}
              dealer_code={dealer_code}
              addressVerificationRequired={isAddressValidationActive}
              providerByCompany={providerByCompany}
            />
          )}
        />
      </Box>
    </TabWrap>
  );
};
export default General;
