import { FC, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material";
import {
  Box,
  CircleLoader,
  FileDragDrop,
  FilePreview,
  Select,
  Textarea,
  NestedMultiSelect,
  Tooltip,
  Grid,
  Input,
  ImageViewer,
  PersistentDrawer,
  Button,
  MultiSelect,
} from "@ntpkunity/controls";
import { FileDragDropWrap, TextAreaWrap } from "@sharedComponents";
import { Controller } from "react-hook-form";
import { validation } from "shared/helper/methods";
import {
  InstallationMode,
  QueryKeys,
  ValidationMessages,
  Vehicle_Type,
  ActionTypes,
  ToastMessages,
} from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import {
  useGetAssetMakeTrims,
  useGetMasterListAccessories,
  useSaveOemOption,
  useUpdateOemOption,
} from "services/options.service";
import { UseQuery_getAllOptionCategorys } from "services/order-category.service";
import {
  IImage,
  IOemOption,
  IOptionCategory,
  ISelectItemList,
} from "Interface";
import {
  UseMutation_uploadS3Image,
  UseMutation_deleteS3Image,
  UseQuery_GetAllAssetTrims,
  useMutation_CheckS3Credentials,
} from "services";
import { useQueryClient } from "react-query";
import { WEBSITE_REGEX } from "shared/config/constants";
interface IFormInputs {
  id: number;
  offered_by: string;
  description: string;
  product_name: string;
  category_id: string;
  part_no: string;
  installation_mode: string;
  price: number;
  is_price_inclusive: boolean;
  rv_adder: number;
  image: string[];
  video_url: string;
  pdf_url: string;
  is_active: boolean;
  compatible_models: any[];
}

const ImgLoader = styled(Box)(() => ({
  ".loader-wrap": {
    ".loader": {
      position: "relative !important",
      transform: "initial !important",
    },
  },
}));

const NewOemOption: FC<{
  categoryDropDownDataProp: any;
  form: any;
  isEditMode: boolean;
  defaultFormValues: IFormInputs;
  setcompatibleModels?: any;
  compatibleModels?: any;
  setProductId?: any;
  productId?: number;
  compatibleTrimModels: any[];
  setImages?: (e: any) => void;
  images?: IImage[];
  setCompatibleTrimModels?: (e: any) => void;
  openPopUp?: boolean;
  setIsEditMode?: any;
  setPopUpState?: any;
}> = ({
  categoryDropDownDataProp,
  form,
  isEditMode,
  defaultFormValues,
  compatibleModels,
  setcompatibleModels,
  compatibleTrimModels,
  setImages,
  images,
  setCompatibleTrimModels,
  openPopUp,
  setIsEditMode,
  setPopUpState,
}) => {
  const theme = useTheme();
  const { actions } = useStoreContext();
  const [imageLoader, setImageLoader] = useState<boolean>(false);
  const { mutate: SaveOemOption } = useSaveOemOption();
  const [selectedCompatibleModels, setSelectedCompatibleModels] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [sliderImages, setSilderImages] = useState([]);
  const [index, setIndex] = useState(0);
  const { mutate: UpdateOemOption } = useUpdateOemOption();
  const { mutate: uploadImage } = UseMutation_uploadS3Image();
  const { mutate: deleteImage } = UseMutation_deleteS3Image();
  const { mutate: getAssetTrims, data: assetTrimsData } =
    useGetAssetMakeTrims();
  const { mutate: checkS3Credentials, data: s3Response } =
    useMutation_CheckS3Credentials();
  const { mutate: getMasterListAccessories, data: masterListAccessoriesData } =
    useGetMasterListAccessories();
  UseQuery_GetAllAssetTrims();
  UseQuery_getAllOptionCategorys;

  const queryClient = useQueryClient();
  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const optionCategories: Array<IOptionCategory> = queryClient.getQueryData(
    QueryKeys.GET_ALL_OPTION_CATEGORIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const allTrims: any = queryClient.getQueryData(QueryKeys.GET_ALL_ASSET_TRIMS);
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");
  const [categoryOptionDropDownData, setCategoryOptionDropDownData] =
    useState<Array<ISelectItemList>>();
  useEffect(() => {
    checkS3Credentials(JSON.parse(localStorage.getItem("settings"))?.tenant_id);
  }, []);
  useEffect(() => {
    queryClient.invalidateQueries(QueryKeys.GET_ALL_OPTION_CATEGORIES_ACTIVE);
    setCategoryOptionDropDownData(
      optionCategories
        ?.filter((x) => x.is_active)
        ?.map((currentOptionCategory) => ({
          value: currentOptionCategory.id,
          text: currentOptionCategory.description,
        }))
    );
  }, [optionCategories]);

  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);

  const {
    control,
    formState: { errors },
    setValue,
    setError,
    watch,
    clearErrors,
  } = form;
  const installationMode = watch("installation_mode");
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children?.length == 0) {
      setPopUpState(false);
    } else {
      setPopUpState(true);
    }
  };
  const resetFields = () => {
    onClose();
    form.reset({} as IFormInputs);
    setIsEditMode(false);
    setImages([]);
    setcompatibleModels(undefined);
  };
  const onSubmit = async (data: any) => {
    if (data) {
      data.category = "";
      data.image = images;
      if (isEditMode) {
        UpdateOemOption(
          {
            ...data,
          },
          {
            onSuccess(response: IOemOption) {
              resetFields();
              actions.setToast({
                toastMessage: "Record Updated Sucessfully",
                toastState: true,
              });
            },
            onError(error: any) {
              if (error?.error?.response?.data?.detail?.code === 400) {
                actions.setToast({
                  toastMessage: error?.error?.response?.data?.detail?.message,
                  toastState: true,
                  variant: "error",
                });
              } else {
                actions.setToast({
                  toastMessage: error.error.toString(),
                  toastState: true,
                  variant: "error",
                });
              }
            },
          }
        );
      } else {
        SaveOemOption(
          {
            ...data,
          },
          {
            onSuccess(response: IOemOption) {
              resetFields();
              actions.setToast({
                toastMessage: "Record Saved Successfully",
                toastState: true,
              });
            },
            onError(error: any) {
              if (error?.error?.response?.data?.detail?.code === 400) {
                actions.setToast({
                  toastMessage: error?.error?.response?.data?.detail?.message,
                  toastState: true,
                  variant: "error",
                });
              } else {
                actions.setToast({
                  toastMessage:
                    error.response.data.detail.message ??
                    ToastMessages.Internal_Server_Error,
                  toastState: true,
                  variant: "error",
                });
              }
            },
          }
        );
      }
    }
  };
  const onimageUpload = (e: any) => {
    setImageLoader(true);
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.target.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          response.data.map((img: IImage) => {
            img.size = file.size;
            img.name = file.name;
            setImages([...images, img]);
          });
        },
      });
    }
  };
  const onimageDrop = (e: any) => {
    setImageLoader(true);
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      const obj: any = {
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      const body = new FormData();
      const file = e.dataTransfer.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response) {
          setImageLoader(false);
          response.data.map((img: IImage) => {
            img.size = file.size;
            img.name = file.name;
            setImages([...images, img]);
          });
        },
      });
    }
  };

  const addFilesForPreview = (files: any) => {
    let fileReader = new FileReader();
    for (let i = 0; i < files.length; i++) {
      fileReader.readAsDataURL(files[i]);
      fileReader.onload = (event) => {
        setImages([...images, files[i]]);
      };
    }
  };

  const onRemoveFile = (fileKey: string, index: number) => {
    if (fileKey) {
      const obj: any = {
        fileKey: fileKey,
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      setImageLoader(true);
      deleteImage(obj, {
        onSuccess(response) {
          const imageArray = images;
          imageArray.splice(index, 1);
          setImages([...imageArray]);
          setImageLoader(false);
        },
      });
    }
  };

  const onClickIcon = (indexNumber) => {
    const image_url = images?.map((image) => {
      return image?.location;
    });
    setIndex(indexNumber);
    setSilderImages(image_url);
    setVisibility(true);
  };

  const handleClose = () => setVisibility(false);

  useEffect(() => {
    getMasterListAccessories();
    getAssetTrims();
  }, []);

  useEffect(() => {
    if (isEditMode) {
      setImages(defaultFormValues?.image);
    } else {
    }
  }, [isEditMode, defaultFormValues]);
  
  return (
    <PersistentDrawer
      title={isEditMode ? "Edit Add-On" : "Add New OEM Add-On"}
      openPopUp={openPopUp}
      setPopUpState={setPopUpState}
      theme={theme}
      enabledPin={true}
      children={
        <>
          <Grid theme={theme} container rowSpacing={3} columnSpacing={2}>
            <Grid theme={theme} item xs={12}>
              <Controller
                name="product_name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: ValidationMessages.PRODUCT_NAME_REQUIRED,
                  },
                }}
                render={({ field }) => (
                  <Input
                    id="productNameId"
                    name="productName"
                    theme={theme}
                    label={"Product Name"}
                    type="text"
                    fullWidth
                    {...field}
                    value={field.value?.trimStart()}
                    error={errors?.product_name?.message}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12}>
              <TextAreaWrap className="mb-0">
                <Controller
                  name="description"
                  control={control}
                  defaultValue={""}
                  rules={validation("Description", true)}
                  render={({ field }) => (
                    <Textarea
                      theme={theme}
                      label={"Description"}
                      error={errors?.description?.message}
                      type="text"
                      id="descriptionSearchId"
                      name="descriptionSearch"
                      rows={3}
                      fullWidth
                      {...field}
                      value={field.value?.trimStart()}
                    />
                  )}
                />
              </TextAreaWrap>
            </Grid>

            <Grid theme={theme} item xs={12}>
              <Controller
                name="category_id"
                control={control}
                rules={validation("Category", true)}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    id="categoryId"
                    name="category"
                    theme={theme}
                    label={"Category"}
                    items={optionCategories
                      ?.filter((x) => x.is_active || x.id == field.value)
                      ?.map((currentOptionCategory) => ({
                        value: currentOptionCategory.id,
                        text: currentOptionCategory.description,
                      }))}
                    // {...field}
                    value={field.value}
                    sxProps={""}
                    selectError={errors?.category_id?.message as never}
                    onChange={field.onChange}
                  ></Select>
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12}>
              <Controller
                name="part_no"
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    label={"Part No. (Optional)"}
                    type="text"
                    id="partNo"
                    fullWidth
                    {...field}
                    value={field.value?.trimStart()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12}>
              <Controller
                name="offered_by"
                control={control}
                rules={validation("Supplier", true)}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    label={"Supplier"}
                    type="text"
                    fullWidth
                    {...field}
                    value={field.value?.trimStart()}
                    error={errors?.offered_by?.message}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12}>
              <Controller
                name="installation_mode"
                control={control}
                defaultValue={""}
                rules={validation("Installed Mode", true)}
                render={({ field }) => (
                  <Select
                    theme={theme}
                    label={"Installed Mode"}
                    items={Object.entries(InstallationMode).map((item) => ({
                      text: item[1],
                      value: item[1],
                    }))}
                    {...field}
                    value={field.value}
                    sxProps={""}
                    onChange={field.onChange}
                    selectError={errors?.installation_mode?.message as never}
                  ></Select>
                )}
              />
            </Grid>
            {installationMode &&
              installationMode === InstallationMode.PRE_INSTALLED && (
                <>
                  <Grid theme={theme} item xs={6}>
                    <Controller
                      name="price"
                      control={control}
                      rules={validation("Price", true)}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          {...field}
                          label={"Price"}
                          placeholder="0.00"
                          value={
                            field.value ? parseFloat(field.value) : undefined
                          }
                          type="text"
                          startAdornment={
                            <>
                              <span className="adornment-text">
                                {defaultCurrency}
                              </span>
                            </>
                          }
                          masking
                          // scale={2}
                          maskDecimalScale={2}
                          maskNumeric
                          // numeric
                          id="price"
                          error={errors?.price?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid theme={theme} item xs={6}>
                    <Controller
                      name="is_price_inclusive"
                      control={control}
                      defaultValue={true}
                      render={({ field }) => (
                        <Select
                          theme={theme}
                          label={""}
                          items={[
                            {
                              text: "Inclusive",
                              value: true,
                            },
                            {
                              text: "Exclusive",
                              value: false,
                            },
                          ]}
                          {...field}
                          value={field.value}
                          sxProps={""}
                          onChange={field.onChange}
                        ></Select>
                      )}
                    />
                  </Grid>
                </>
              )}
            {(!installationMode ||
              installationMode === InstallationMode.OPTIONAL) && (
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="price"
                  control={control}
                  rules={validation("Price", true)}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      fullWidth
                      label={"Price"}
                      type="text"
                      startAdornment={
                        <>
                          <span className="adornment-text">
                            {defaultCurrency}
                          </span>
                        </>
                      }
                      value={field.value ? parseFloat(field.value) : undefined}
                      masking
                      maskDecimalScale={2}
                      maskNumeric
                      id="price"
                      {...field}
                      error={errors?.price?.message}
                    />
                  )}
                />
              </Grid>
            )}

            {assetTrimsData && (
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="compatible_models"
                  control={control}
                  rules={{
                    validate: (_value) => {
                      if (
                       !_value || _value.length < 1
                      ) {
                        return "Compatible models are required";
                      }
                      return true;
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <MultiSelect
                      theme={theme}
                      id="drawerMultiSelectCompatibleModels"
                      sxProps={{ m: 0, width: "100%" }}
                      label={"Compatible Models"}
                      placeholder={"Select"}
                      items={assetTrimsData
                        ?.filter((row) => row.is_active)
                        ?.map((model: any) => {
                          return {
                            text: model.model_name,
                            value: model.id,
                          };
                        })}
                      value={value ?? []}
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                      selectError={errors?.compatible_models?.message}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid theme={theme} item xs={12}>
              <Controller
                name="rv_adder"
                control={control}
                rules={validation("RV On Add-On", true)}
                render={({ field }) => (
                  <Input
                    //disabled={true}
                    theme={theme}
                    fullWidth
                    label={"RV On Add-On"}
                    type="decimal"
                    id="rvAdder"
                    startAdornment={
                      <>
                        <span className="adornment-text">
                          {defaultCurrency}
                        </span>
                      </>
                    }
                    value={field.value ? parseFloat(field.value) : undefined}
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                    {...field}
                    error={errors?.rv_adder?.message}
                  />
                )}
              />
            </Grid>

            <Grid theme={theme} item xs={12}>
              <FileDragDropWrap>
                <Controller
                  name="image"
                  control={control}
                  render={({ field }) => (
                    <Tooltip
                      theme={theme}
                      title={
                        !s3Response?.data
                          ? ActionTypes.STORAGE_PATH_NOT_CONFIGURED
                          : ""
                      }
                    >
                      <FileDragDrop
                        {...field}
                        hoverLabel="Drag and drop product images, or browse"
                        width="300"
                        height="200px"
                        backgroundColor="white"
                        onDrop={onimageDrop}
                        onChange={onimageUpload}
                        disabled={!s3Response?.data}
                        theme={theme}
                      />
                    </Tooltip>
                  )}
                />
                <FilePreview
                  files={images?.length > 0 ? images : []}
                  onRemoveFile={onRemoveFile}
                  viewIcon={true}
                  theme={theme}
                  onClickIcon={onClickIcon}
                  preview="image"
                />
                {imageLoader && (
                  <ImgLoader theme={theme} sx={{ mb: 1, mt: 3 }}>
                    <CircleLoader theme={theme} size="xs" />
                  </ImgLoader>
                )}
              </FileDragDropWrap>
            </Grid>
            <Grid theme={theme} item xs={12}>
              <Controller
                name="video_url"
                control={control}
                rules={validation(
                  "Video Url",
                  false,
                  true,
                  WEBSITE_REGEX,
                  "Valid URL is required."
                )}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    label={"Video URL (Optional)"}
                    type="text"
                    id="videoUrl"
                    fullWidth
                    {...field}
                    value={field.value?.trimStart()}
                    error={errors?.video_url?.message}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12}>
              <Controller
                name="pdf_url"
                control={control}
                rules={validation(
                  "Brochure Url",
                  false,
                  true,
                  WEBSITE_REGEX,
                  "Valid URL is required."
                )}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    label={"Brochure URL (Optional)"}
                    type="text"
                    id="videoUrl"
                    fullWidth
                    {...field}
                    value={field.value?.trimStart()}
                    error={errors?.pdf_url?.message}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12}>
              <Controller
                name="is_active"
                control={control}
                defaultValue={true}
                render={({ field: { value, onChange } }) => (
                  <Select
                    theme={theme}
                    label={"Status"}
                    defaultValue={true}
                    items={[
                      { text: "Enabled", value: true },
                      { text: "Disabled", value: false },
                    ]}
                    value={value}
                    sxProps={""}
                    onChange={onChange}
                  ></Select>
                )}
              />
            </Grid>
          </Grid>
          <ImageViewer
            theme={theme}
            sliderImagesWithVisible={true}
            sliderImages={sliderImages}
            visible={visibility}
            onClose={handleClose}
            overlayCheck={true}
            index={index}
            setIndex={setIndex}
          />
        </>
      }
      customFooter={
        <Button
          theme={theme}
          primary
          type="submit"
          text="Save"
          fullWidth
          onClick={form.handleSubmit(onSubmit)}
        />
      }
    />
  );
};

export default NewOemOption;
