import { useTheme } from "@mui/material";
import {
  Autocomplete,
  DatePicker,
  Input,
  MultiSelect,
  Select,
} from "@ntpkunity/controls";
import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { validation } from "shared/helper/methods";
import { useQueryClient } from "react-query";
import {
  ActionOptions,
  QueryKeys,
  ToastMessages,
  ValidationMessages,
} from "Enums";
import {
  UseQuery_GetAllAssetMakes,
  UseQuery_GetAllAssetModels,
  UseQuery_GetAllAssetTrims,
} from "services";
import { UseQuery_GetAllCompatibleOptions } from "services/inventory-management.service";
import {
  IAssetMake,
  IAssetModel,
  IAssetTrim,
  ISelectItemList,
  ISelectAssetTrim,
} from "Interface";
export const AddNewVehicle: FC<{
  form: any;
  setAssetMakeId: any;
  assetMakeId: any;
  setAssetModelId: any;
  assetModelId: any;
  setAssetTrimId: any;
  assetTrimId: any;
  popUpOpenState: string;
  selected: number[];
  setSellingPrice: any;
  sellingPrice: string;
  setAssetTrimCode: any;
  modelName: string[];
  dealer_code: any;
}> = ({
  form,
  setAssetMakeId,
  assetMakeId,
  setAssetModelId,
  assetModelId,
  setAssetTrimId,
  assetTrimId,
  popUpOpenState,
  selected,
  sellingPrice,
  setSellingPrice,
  setAssetTrimCode,
  modelName,
  dealer_code,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = form;
  const theme = useTheme();

  const { data, refetch } = UseQuery_GetAllCompatibleOptions(
    modelName,
    dealer_code
  );

  const queryClient = useQueryClient();
  UseQuery_GetAllAssetMakes();
  UseQuery_GetAllAssetModels();
  UseQuery_GetAllAssetTrims();

  const assetMakes: Array<IAssetMake> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MAKES
  );
  const assetModels: Array<IAssetModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MODELS
  );
  const assetTrims: Array<IAssetTrim> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_TRIMS
  );
  const [assetMake, setAssetMake] = useState(null);
  const [assetModel, setAssetModel] = useState(null);
  useEffect(() => {
    setAssetmakesSelection(
      assetMakes
        ?.filter((x) => x.is_active)
        .map((makes) => ({
          value: makes.name,
          text: makes.name,
        }))
    );
  }, [assetMakes, assetMakeId]);
  const onMakeSelection = (value) => {
    setAssetMakeId(value?.id);
    setAssetModelId(null);
    setAssetTrimId(null);
  };
  const onModelSelection = (value) => {
    setValue("model_code", value?.code);
    setAssetModelId(value?.id);
    setAssetTrimId(null);
  };

  const onTrimSelection = (value) => {
    setValue("assest_trim_code", value?.code);
    setAssetTrimId(value?.id);
  };
  const [assetmakesSelection, setAssetmakesSelection] = useState<
    Array<ISelectItemList>
  >(
    assetMakes
      ?.filter((x) => x.is_active)
      .map((make) => ({
        value: make.id,
        text: make.name,
      }))
  );

  useEffect(() => {
    setAssetmakesSelection(
      assetMakes
        ?.filter((x) => x.is_active)
        .map((makes) => ({
          value: makes.name,
          text: makes.name,
        }))
    );
  }, [assetMakes, assetMakeId]);

  const [assetmodelsSelection, setAssetmodelsSelection] = useState<
    Array<ISelectItemList>
  >([]);

  const [assetTrimSelection, setAssettrimSelection] = useState<
    Array<ISelectAssetTrim>
  >([]);
  useEffect(() => {
    setAssetMake(assetMakes?.filter((x) => x.name == assetMakeId)[0]?.id);
    setAssetmodelsSelection(
      assetModels
        ?.filter(
          (models) =>
            assetMake > 0 && models.make_id === assetMake && models.is_active
        )
        ?.map((model) => ({
          value: model.model_name,
          text: model.model_name,
          model_code: model.model_code,
        }))
    );
  }, [assetModels, assetMakes, assetMakeId, assetModelId, assetMake]);
  useEffect(() => {
    setAssetModel(
      assetModels?.filter((x) => x.model_name == assetModelId)[0]?.id
    );
    setAssettrimSelection(
      assetTrims
        ?.filter(
          (trims) =>
            assetModel > 0 && trims.model_id === assetModel && trims.is_active
        )
        ?.map((trim) => ({
          value: trim.trim_name,
          text: trim.trim_name,
          code: trim?.trim_code,
        }))
    );
  }, [
    assetTrims,
    assetModels,
    assetMakes,
    assetMakeId,
    assetModelId,
    assetTrimId,
    assetModel,
  ]);
  const formatValue = (value) => {
    if (value === "") return "";
    const num = parseFloat(value);
    return !isNaN(num) ? num.toFixed(2) : "";
  };
  useEffect(() => {
    if (
      popUpOpenState === ActionOptions.BULK_UPDATE &&
      modelName?.length > 0 &&
      dealer_code
    ) {
      refetch();
    }
  }, [popUpOpenState, modelName, dealer_code, refetch]);

  return popUpOpenState === ActionOptions.ADD_VEHICLE ? (
    <>
      <Controller
        name="stock_number"
        control={control}
        defaultValue={""}
        rules={{
          required: ValidationMessages.STOCK_NUMBER_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Stock Number"}
            type="text"
            {...field}
            error={errors?.stock_number?.message}
          />
        )}
      />
      <Controller
        name="vin"
        control={control}
        defaultValue={""}
        rules={{
          required: ValidationMessages.VIN_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"VIN"}
            type="text"
            {...field}
            error={errors?.vin?.message}
          />
        )}
      />
      <Controller
        name="year"
        control={control}
        defaultValue={""}
        rules={{
          required: ValidationMessages.YEAR_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Year"}
            type="text"
            {...field}
            error={errors?.year?.message}
          />
        )}
      />
      <Controller
        name="make"
        control={control}
        rules={{
          required: ValidationMessages.ASSET_MAKE_REQUIRED,
        }}
        render={({ field }) => (
          <Autocomplete
            disablePortal
            {...field}
            id="drawerMake"
            theme={theme}
            capitalizeLabel={true}
            items={
              assetmakesSelection
                ? assetmakesSelection?.map((option: any) => {
                    return { label: option.text, id: option.value };
                  })
                : []
            }
            onChange={(_e: any, value: any) => {
              onMakeSelection(value);
              field.onChange(value);
            }}
            label={assetMakeId ? "Make" : "Make"}
            value={
              assetMakeId
                ? {
                    label: assetMakeId,
                    id: assetMakeId,
                  }
                : { label: "", id: "" }
            }
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <span>{option.label}</span>
              </li>
            )}
          />
        )}
      />
      <Controller
        name="model"
        control={control}
        rules={{
          required: ValidationMessages.MODEL_NAME_REQUIRED,
        }}
        render={({ field }) => (
          <Autocomplete
            disablePortal
            id="drawerModel"
            {...field}
            theme={theme}
            capitalizeLabel={true}
            items={
              assetmodelsSelection
                ? assetmodelsSelection?.map((option: any) => {
                    return {
                      label: option.text,
                      id: option.value,
                      code: option.model_code,
                    };
                  })
                : []
            }
            onChange={(_e: any, value: any) => {
              onModelSelection(value);
              field.onChange(value);
            }}
            label={assetModelId ? "Model" : "Model"}
            value={
              assetModelId
                ? {
                    label: assetModelId,
                    id: assetModelId,
                  }
                : { label: "", id: "" }
            }
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <span>{option.label}</span>
              </li>
            )}
          />
        )}
      />
      <Controller
        name="trim"
        control={control}
        render={({ field }) => (
          <Autocomplete
            disablePortal
            id="drawerTrim"
            {...field}
            theme={theme}
            capitalizeLabel={true}
            items={
              assetTrimSelection
                ? assetTrimSelection?.map((option: any) => {
                    return {
                      label: option.text,
                      id: option.text,
                      code: option.code,
                    };
                  })
                : []
            }
            onChange={(_e: any, value: any) => {
              onTrimSelection(value);
              field.onChange(value);
            }}
            label={assetTrimId ? "Trim" : "Trim"}
            value={
              assetTrimId
                ? {
                    label: assetTrimId,
                    id: assetTrimId,
                  }
                : { label: "", id: "" }
            }
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <span>{option.label}</span>
              </li>
            )}
          />
        )}
      />
      <Controller
        name="selling_price"
        control={control}
        defaultValue={""}
        rules={{
          required: ValidationMessages.SELLING_PRICE_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Selling Price"}
            type="text"
            {...field}
            error={errors?.selling_price?.message}
          />
        )}
      />
      <Controller
        name="msrp"
        control={control}
        defaultValue={""}
        rules={{
          required: ValidationMessages.MSRP_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"MSRP"}
            type="text"
            {...field}
            error={errors?.msrp?.message}
          />
        )}
      />
      <Controller
        name="mileage"
        control={control}
        defaultValue={""}
        rules={{
          required: ValidationMessages.MILEAGE_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Mileage"}
            type="text"
            {...field}
            error={errors?.mileage?.message}
          />
        )}
      />
      <Controller
        name="listing_status"
        control={control}
        defaultValue={"Listed"}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Listing Status"}
            placeholder={"Select"}
            items={[
              { text: "Listed", value: "Listed" },
              { text: "Not Listed", value: "Not Listed" },
            ]}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="asset_condition"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Asset Condition"}
            type="text"
            {...field}
          />
        )}
      />
      <Controller
        name="exterior_color_description"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Exterior Color Description"}
            type="text"
            {...field}
          />
        )}
      />
      <Controller
        name="interior_color_description"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Interior Color Description"}
            type="text"
            {...field}
          />
        )}
      />
      <Controller
        name="trasnmission_type"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Transmission Type"}
            placeholder={"Select"}
            items={[
              { text: "Manual", value: "Manual" },
              { text: "CVT", value: "CVT" },
            ]}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="body_type"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Body Type"}
            placeholder={"Select"}
            items={[
              { text: "Sedan", value: "Sedan" },
              { text: "SUV", value: "SUV" },
              { text: "Hatchback", value: "Hatchback" },
            ]}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="doors"
        control={control}
        defaultValue={"2"}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Doors"}
            placeholder={"Select"}
            items={[
              { text: "2", value: "2" },
              { text: "4", value: "4" },
            ]}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="body_style"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Body Style"}
            placeholder={"Select"}
            items={[{ text: "Compact Saloon", value: "Compact Saloon" }]}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="engine_cylinders"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Engine Cylinders"}
            placeholder={"Select"}
            items={[
              { text: "2", value: "2" },
              { text: "4", value: "4" },
            ]}
            value={value ?? ""}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="engine_description"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Engine Description"}
            type="text"
            {...field}
          />
        )}
      />
      <Controller
        name="image_url"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Image URL"}
            type="text"
            {...field}
          />
        )}
      />
      <Controller
        name="test_drive"
        control={control}
        defaultValue={"No"}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Test Drive"}
            placeholder={"Select"}
            items={[
              { text: "Yes", value: "Yes" },
              { text: "No", value: "No" },
            ]}
            value={value ?? ""}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="status"
        control={control}
        defaultValue={"Available"}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Status"}
            placeholder={"Select"}
            items={[
              { text: "Available", value: "Available" },
              { text: "Not Available", value: "Not Available" },
            ]}
            value={value ?? ""}
            onChange={onChange}
          />
        )}
      />
    </>
  ) : (
    <>
      <Controller
        name="attached_options"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Add-Ons"}
            placeholder={"Select"}
            items={data?.map((item) => {
              return {
                text: item?.product_name,
                value: item?.id,
              };
            })}
            value={value ?? ""}
            onChange={(e) => {
              onChange(e);
            }}
          />
        )}
      />
      <Controller
        name="listing_status"
        control={control}
        defaultValue={""}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Listing Status"}
            placeholder={"Select"}
            items={[
              { text: "Listed", value: "true" },
              { text: "Not Listed", value: "false" },
            ]}
            value={value ?? ""}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="customize_selling_price"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Customize Selling Price"}
            placeholder={"Select"}
            items={[
              { text: "Yes", value: "Yes" },
              { text: "No", value: "No" },
            ]}
            value={value ?? ""}
            onChange={(e) => {
              setSellingPrice(e?.target?.value as any);
              onChange(e);
            }}
          />
        )}
      />
      {sellingPrice === "Yes" && (
        <Controller
          name="operator"
          control={control}
          defaultValue={""}
          rules={{
            required: ValidationMessages.OPERATER_REQUIRED,
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={"Operater"}
              placeholder={"Select"}
              items={[
                { text: "Add", value: "add" },
                { text: "Substact", value: "subtract" },
              ]}
              value={value ?? ""}
              onChange={(e) => {
                onChange(e);
              }}
            />
          )}
        />
      )}
      {sellingPrice === "Yes" && (
        <Controller
          name="selling_price"
          control={control}
          defaultValue={""}
          rules={{
            required: ValidationMessages.SELLING_PRICE_REQUIRED,
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              theme={theme}
              fullWidth={true}
              placeholder={"Type here.."}
              label={"Selling Price"}
              type="text"
              startAdornment=""
              id="markupDrawer"
              onChange={(e) => {
                onChange(e);
              }}
              value={value}
              masking={true}
              maskNumeric={true}
              maskDecimalScale={2}
              maskAllowNegative
              error={errors?.selling_price?.message}
            />
          )}
        />
      )}
      {sellingPrice === "No" && (
        <Controller
          name="selling_price"
          control={control}
          defaultValue={""}
          rules={{
            required: ValidationMessages.SELLING_PRICE_REQUIRED,
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              theme={theme}
              fullWidth={true}
              placeholder={"Type here.."}
              label={"Selling Price"}
              type="text"
              startAdornment=""
              id="markupDrawer"
              onChange={(e) => {
                onChange(e);
              }}
              value={value}
              masking={true}
              maskNumeric={true}
              maskDecimalScale={2}
              maskAllowNegative
              error={errors?.selling_price?.message}
            />
          )}
        />
      )}
    </>
  );
};
