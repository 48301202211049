import { FC, Fragment, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Box, Input, Button, PersistentDrawer } from "@ntpkunity/controls";
import { ActionOptions, ValidationMessages } from "Enums";
import { Controller, useForm } from "react-hook-form";
import { useMutation_AddPlaceholderAndTranslation } from "services";
import {
  ILanguageTranslationModel,
  IPlaceholderTranslationModel,
} from "Interface";

const Translation: FC<{
  open: boolean;
  setOpen: any;
  languagesAndTranslation: ILanguageTranslationModel;
  selectedData: IPlaceholderTranslationModel;
  action: string;
  setselectedData?: (data?: IPlaceholderTranslationModel) => unknown;
}> = ({
  open,
  setOpen,
  languagesAndTranslation,
  selectedData,
  action,
  setselectedData,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IPlaceholderTranslationModel>({
    defaultValues: {
      placeholder: selectedData?.placeholder,
    },
  });

  const theme = useTheme();
  const { mutate: addPlaceholderAndTranslation, isLoading } =
    useMutation_AddPlaceholderAndTranslation();

  const onSubmit = (data) => {
    const updatedTranslations = data.translations.map((item) => ({
      ...item,
      translation: item.translation || "",
    }));
    const updatedData = {
      ...data,
      translations: updatedTranslations,
    };
    setselectedData(updatedData);
    addPlaceholderAndTranslation([updatedData] as any, {
      onSuccess() {
        setOpen(false);
      },
    });
  };

  useEffect(() => {
    if (action == ActionOptions.ADD) {
      setselectedData(undefined);
      reset();
    }
  }, [action]);

  return (
    <>
      <PersistentDrawer
        openPopUp={open}
        setPopUpState={setOpen}
        title={` ${
          action == ActionOptions.ADD ? "Add New" : "Edit"
        } Placeholder`}
        theme={theme}
        children={
          <>
            {setValue(
              "placeholder",
              action == ActionOptions.ADD ? "" : selectedData?.placeholder
            )}
            <Controller
              name="placeholder"
              control={control}
              rules={{
                required: ValidationMessages.PLACEHOLDER_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="placeholder"
                  testid="placeholderTest"
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Placeholder"}
                  type="string"
                  onChange={onChange}
                  value={value}
                  error={errors?.placeholder?.message}
                  disabled={action !== ActionOptions.ADD}
                ></Input>
              )}
            />
            {languagesAndTranslation?.languages?.map((language, index) => {
              const selectedTranslation = selectedData?.translations?.find(
                (f) => f?.languages?.code === language?.code
              );
              setValue(
                `translations.${index}.translation`,
                action == ActionOptions.ADD
                  ? ""
                  : selectedTranslation?.translation
              );
              return (
                <Fragment key={index}>
                  <Controller
                    name={`translations.${index}.translation`}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Input
                          id="languageId"
                          testid="languageTest"
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Type here..."}
                          label={
                            selectedTranslation?.languages?.name ??
                            language?.name
                          }
                          type="string"
                          onChange={onChange}
                          value={value}
                        ></Input>
                      );
                    }}
                  />
                  <Box theme={theme} display={"none"}>
                    <Controller
                      name={`translations.${index}.languages.code`}
                      control={control}
                      defaultValue={language?.code}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          id="languageId"
                          testid="languageTest"
                          theme={theme}
                          type="hidden"
                          fullWidth={true}
                          placeholder={"Type here..."}
                          onChange={onChange}
                          label={"code"}
                          value={
                            selectedTranslation?.languages?.code ??
                            language?.code
                          }
                        ></Input>
                      )}
                    />
                  </Box>
                </Fragment>
              );
            })}
          </>
        }
        customFooter={
          <Button
            primary
            theme={theme}
            text={"Save Changes"}
            fullWidth={true}
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};

export default Translation;
