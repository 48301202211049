import { FC, useEffect, useState } from "react";
import { useTheme, styled } from "@mui/material";
import {
  Icon,
  PersistentDrawer,
  Input,
  Select,
  Button,
  FilePreview,
  Tooltip,
  CircleLoader,
  Box,
  FileDragDrop,
  MultiSelect,
} from "@ntpkunity/controls";
import {
  ConfirmationDialog,
  FileDragDropWrap,
  MultiSelectWrap,
} from "@sharedComponents";
import { validation } from "shared/helper/methods";
import { Controller, SubmitHandler, set, useForm } from "react-hook-form";
import {
  ActionOptions,
  QueryKeys,
  ValidationMessages,
  DialogMessages,
} from "Enums";
import { useQueryClient } from "react-query";
import { INewIntegrationTypePopup } from "./AddNewIntegrations.type";
import {
  UseQuery_getLenderIntegrations,
  useSaveIntegration,
  useUpdateLenderIntegration,
  useGetVerifyAddress,
  UseGetHubexCredentials,
  UseUpdateHubexCredentials,
  UseSaveHubexCredentials,
} from "services";
import { useStoreContext } from "Stores/SetupStore/Store";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";
import integrationData from "utilities/IntegrationData";
import {
  IntegrationType,
  Providers,
  IntegrationLevel,
} from "../../Enums/IntegrationTypesAndProviders";
import { ITenantConfiguration } from "Interface";
import { INTEGRATION_VALIDATION_FIELDS } from "shared/config/constants";
import { useHasPermissions, indexPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "Enums";

const ImgLoader = styled(Box)(() => ({
  ".loader-wrap": {
    ".loader": {
      position: "relative !important",
      transform: "initial !important",
    },
  },
}));
const AddNewIntegration: FC<INewIntegrationTypePopup> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  integrationTypeData,
  allIntegrations,
  enabledPin,
  ParentIntegrationState,
}) => {
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");

    if (children.length == 0) {
      setPopUpState(false);
    }
  };

  const defaultValues = {
    id: null,
    integration_type: "",
    route: "",
    provider_name: "",
    is_active: true,
    providers: [],
    tax_service_type: "",
    signing_initiation: "",
    default_pricing_program: "",
    is_company_level: true,
    certificate_file: "",
  };

  interface IFormInputs {
    id: number;
    integration_type: string;
    route: string;
    provider_name: string;
    is_active: boolean;
    providers: Array<IFormProviders>;
    tax_service_type?: string;
    signing_initiation: string;
    default_pricing_program: string;
    is_company_level?: boolean;
    certificate_file?: string;
  }

  interface IFormProviders {
    id: number;
    is_active: boolean;
    integration_id: number;
    provider_name: string;
    provider_configuration?: object;
  }

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors, isDirty },
    watch,
  } = useForm<IFormInputs>();
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [providerItems, setProvidersItems] = useState<Array<any>>([]);
  const [saveButtonState, setSaveButtonState] = useState<boolean>(false);
  const [inputFields, setInputFields] = useState<any>(undefined);
  const [files, setFiles] = useState([]);
  const [certificate, setCertificate] = useState("");
  const [imageLoader, setImageLoader] = useState<boolean>(false);
  const { mutate: createLenderIntegration } = useSaveIntegration();
  const { mutate: updateLenderIntegration } = useUpdateLenderIntegration();
  const { mutate: getHubexCredentials, data: hubexConfigurations } =
    UseGetHubexCredentials();
  const { mutateAsync: updateHubexCredentials } = UseUpdateHubexCredentials();
  const { mutateAsync: saveHubexCredentials } = UseSaveHubexCredentials();
  const navigate = useNavigate();
  const theme = useTheme();
  const integration_data_copy = JSON.parse(JSON.stringify(integrationData));
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const tenantConfigurationList: ITenantConfiguration[] =
    queryClient.getQueryData(QueryKeys.GET_TENANT_CONFIGURATION);
  const [lenderIntegrations, setLenderIntegrations] = useState(
    JSON.parse(JSON.stringify(integrationData))
      .integration_types.filter(
        (integration) =>
          integration?.subscription === IntegrationLevel.COMPANY ||
          integration?.subscription === IntegrationLevel.BOTH
      )
      .map((integration) => {
        const selectedProviders = new Set();
        let tenantSpecificLogicApplied = false;
        if (tenantConfigurationList?.length > 0) {
          tenantConfigurationList.forEach((tenantConfig) => {
            const { tenant_name } = tenantConfig;
            if (
              integration.integration_type ===
              IntegrationType.INVENTORY_MANAGEMENT
            ) {
              tenantSpecificLogicApplied = true;
              if (tenant_name === "BMW") {
                selectedProviders.add(Providers.BMW_INVENTORY);
                selectedProviders.add(Providers.SHIFTDIGITAL);
                selectedProviders.add(Providers.HOMENET);
              } else {
                selectedProviders.add(Providers.SHIFTDIGITAL);
                selectedProviders.add(Providers.HOMENET);
              }
            }

            if (
              integration.integration_type ===
              IntegrationType.CUSTOMER_MANAGEMENT
            ) {
              tenantSpecificLogicApplied = true;
              if (tenant_name === "Ascent") {
                selectedProviders.add(Providers.ASCENT);
              }
            }

            if (integration.integration_type === IntegrationType.FNI_PRODUCTS) {
              tenantSpecificLogicApplied = true;
              if (tenant_name === "BMW") {
                selectedProviders.add(Providers.BMW_PROGRAMS);
                selectedProviders.add(Providers.PEN);
              } else {
                selectedProviders.add(Providers.PEN);
              }
            }

            if (
              integration.integration_type === IntegrationType.PRICING_PROGRAM
            ) {
              tenantSpecificLogicApplied = true;
              if (tenant_name === "BMW") {
                selectedProviders.add(Providers.BMW_PRICING);
              } else if (tenant_name === "Ascent") {
                selectedProviders.add(Providers.ASCENT);
              }
            }

            if (
              integration.integration_type ===
              IntegrationType.CREDIT_DECISIONING
            ) {
              tenantSpecificLogicApplied = true;
              if (tenant_name === "BMW") {
                selectedProviders.add(Providers.BMW_DEALS);
                selectedProviders.add(Providers.DEALER_TRACKML);
                selectedProviders.add(Providers.ROUTEONE);
              } else if (tenant_name === "Ascent") {
                selectedProviders.add(Providers.ASCENT);
                selectedProviders.add(Providers.DEALER_TRACKML);
                selectedProviders.add(Providers.ROUTEONE);
              } else {
                selectedProviders.add(Providers.DEALER_TRACKML);
                selectedProviders.add(Providers.ROUTEONE);
              }
            }

            if (integration.integration_type === IntegrationType.CONTRACTING) {
              tenantSpecificLogicApplied = true;
              if (tenant_name === "BMW") {
                selectedProviders.add(Providers.BMW_CONTRACTS);
              } else if (tenant_name === "Ascent") {
                selectedProviders.add(Providers.ASCENT);
              }
            }
          });
        } else {
          if (
            integration.integration_type ===
            IntegrationType.INVENTORY_MANAGEMENT
          ) {
            tenantSpecificLogicApplied = true;
            selectedProviders.add(Providers.SHIFTDIGITAL);
            selectedProviders.add(Providers.HOMENET);
          }

          if (
            integration.integration_type === IntegrationType.CUSTOMER_MANAGEMENT
          ) {
            tenantSpecificLogicApplied = true;
            selectedProviders.add(null);
          }

          if (integration.integration_type === IntegrationType.FNI_PRODUCTS) {
            tenantSpecificLogicApplied = true;
            selectedProviders.add(Providers.PEN);
          }

          if (
            integration.integration_type === IntegrationType.PRICING_PROGRAM
          ) {
            tenantSpecificLogicApplied = true;
            selectedProviders.add(null);
          }

          if (
            integration.integration_type === IntegrationType.CREDIT_DECISIONING
          ) {
            tenantSpecificLogicApplied = true;
            selectedProviders.add(Providers.DEALER_TRACKML);
            selectedProviders.add(Providers.ROUTEONE);
          }

          if (integration.integration_type === IntegrationType.CONTRACTING) {
            tenantSpecificLogicApplied = true;
            selectedProviders.add(null);
          }
        }

        return {
          ...integration,
          provider_name: tenantSpecificLogicApplied
            ? integration.provider_name.filter((provider) =>
                selectedProviders.has(provider.value)
              )
            : integration.provider_name,
        };
      })
  );

  const integrationOptions = lenderIntegrations
    .filter((item) => {
      if (item.integration_type === "Proposal Submission") {
        const result = useHasPermissions(
          indexPermissions?.INTEGRATION?.PROPOSAL_SUBMISSION,
          PermissionTypes.VIEW
        );
        return result;
      }
      return true;
    })
    .map((item) => {
      return {
        value: item?.integration_type,
        text: item?.integration_type,
      };
    });

  const removeAddedProviders = (integration_type, integrations) => {
    const filteredProviders = integrations?.flatMap((item) =>
      item.providers?.map((provider) => provider.provider_name)
    );

    const filteredIntg = lenderIntegrations?.filter(
      (item) => item.integration_type === integration_type
    );

    const remainingProviders = filteredIntg
      ?.flatMap((item) =>
        item.provider_name?.map((provider) => ({
          name: provider.name,
          value: provider.value,
        }))
      )
      ?.filter(
        (providerName) => !filteredProviders?.includes(providerName.value)
      );

    const dropdownProvides = remainingProviders.map((provider) => ({
      text: provider.name,
      value: provider.value,
    }));

    setProvidersItems(dropdownProvides);
  };

  const setProviderValues = (integration_type) => {
    const integrations = allIntegrations.filter(
      (integration) => integration.integration_type === integration_type
    );

    if (integrations) {
      removeAddedProviders(integration_type, integrations);
    }
    if (!integrations) {
      const filteredInt = lenderIntegrations?.filter(
        (item) => item.integration_type === integration_type
      );
      const dropdownItems = filteredInt[0]?.provider_name?.map((provider) => ({
        text: provider.name,
        value: provider.value,
      }));
      setProvidersItems(dropdownItems);
    }
  };

  const handleInputFields = (
    provider_name: string,
    integration_type = watch("integration_type")
  ) => {
    const providers: any = integration_data_copy?.integration_types?.find(
      (x) => x.integration_type == integration_type
    )?.provider_name;
    const selectedProvider = providers?.find((x) => x.value == provider_name);

    if (selectedProvider.value == Providers.MARKETSCAN) {
      selectedProvider.user_input = selectedProvider.user_input.filter(
        (input) => input.name !== "account_number"
      );
    }
    if (selectedProvider.value == Providers.SHIFTDIGITAL) {
      selectedProvider.user_input = selectedProvider.user_input.filter(
        (input) => input.name !== "shift_digital_id"
      );
    }
    if (selectedProvider.value == Providers.PAYOFF_ROUTEONE) {
      selectedProvider.user_input = selectedProvider.user_input.filter(
        (input) => input.name !== "payoff_dealer_id"
      );
    }
    if (selectedProvider.value == Providers.HOMENET) {
      selectedProvider.user_input = selectedProvider.user_input.filter(
        (input) => input.name !== "home_net_dealer_id"
      );
    }

    setInputFields(selectedProvider.user_input);

    reset({
      integration_type: getValues("integration_type"),
      provider_name: provider_name,
      is_active: getValues("is_active"),
      route: getValues("route"),
      tax_service_type: getValues("tax_service_type"),
      signing_initiation: getValues("signing_initiation"),
      default_pricing_program: getValues("default_pricing_program"),
    });
  };

  useEffect(() => {
    getHubexCredentials();
  }, [companyProfile]);

  useEffect(() => {
    if (actionType == ActionOptions.EDIT) {
      setValue("route", integrationTypeData?.route);
      const integrations = allIntegrations?.filter(
        (item) =>
          item.integration_type === integrationTypeData?.integration_type
      );
      removeAddedProviders(integrationTypeData?.integration_type, integrations);
    }
  }, [actionType, allIntegrations]);

  const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
    setSaveButtonState(true);
    const { isCredentialsAdded, configuration_payload } =
      await storeCredentialsAtHubex(data);
    if (!isCredentialsAdded) {
      onClose();
      return;
    }
    if (actionType == ActionOptions.ADD) {
      data.id = 0;
      data.providers = [
        {
          id: 0,
          integration_id: 0,
          is_active: true,
          provider_name: data.provider_name,
          provider_configuration: configuration_payload ?? null,
        },
      ];
      data.tax_service_type =
        getValues("provider_name") === Providers.MARKETSCAN
          ? data.tax_service_type
          : null;
      data.signing_initiation =
        getValues("provider_name") === Providers.BMW_CONTRACTS &&
        Providers.ASCENT
          ? data.signing_initiation
          : null;
      data.default_pricing_program =
        getValues("provider_name") === Providers.BMW_PRICING && Providers.ASCENT
          ? data.default_pricing_program
          : null;
      data.is_company_level = true;
      delete data.provider_name;

      createLenderIntegration(data, {
        onSuccess: (response) => {
          setSaveButtonState(false);
          const integrations = allIntegrations?.filter(
            (item) =>
              item.integration_type === integrationTypeData?.integration_type
          );
          removeAddedProviders(
            integrationTypeData?.integration_type,
            integrations
          );
          reset(defaultValues);
          onClose();
        },
        onError: (response) => {
          setSaveButtonState(false);
          onClose();
        },
      });
    }

    if (actionType == ActionOptions.EDIT) {
      data.id = integrationTypeData.id;
      data.providers = [
        {
          id: 0,
          integration_id: integrationTypeData.id,
          is_active: true,
          provider_name: data.provider_name,
        },
      ];
      data.is_company_level = true;
      delete data.provider_name;

      updateLenderIntegration(data, {
        onSuccess: (response) => {
          setSaveButtonState(false);
          const integrations = allIntegrations?.filter(
            (item) =>
              item.integration_type === integrationTypeData?.integration_type
          );
          removeAddedProviders(
            integrationTypeData?.integration_type,
            integrations
          );
          reset();
          onClose();
          setValue("provider_name", "");
        },
        onError: (response) => {
          setSaveButtonState(false);
          onClose();
        },
      });
    }
  };

  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
    setSaveButtonState(false);
  };

  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };

  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );

  const storeCredentialsAtHubex = async (data) => {
    let requestData: any = null;
    let isCredentialsAdded = true;
    let configuration_payload = null;
    switch (data.provider_name) {
      case Providers.BMW_INVENTORY:
        if (
          data?.api_url &&
          data?.token_url &&
          data?.client_id &&
          data?.client_secret
        ) {
          requestData = {
            inventory_management: {
              bmw_inventory: {
                api_url: data?.api_url,
                token_url: data?.token_url,
                client_id: data?.client_id,
                client_secret: data?.client_secret,
              },
            },
          };
        }
        break;
      case Providers.SIEMENS:
        if (
          data?.api_url &&
          data?.token_url &&
          data?.client_id &&
          data?.client_secret &&
          data?.audience &&
          data?.webhook_url
        ) {
          requestData = {
            application_management_system: {
              siemens: {
                api_url: data?.api_url,
                token_url: data?.token_url,
                client_id: data?.client_id,
                client_secret: data?.client_secret,
                audience: data?.audience,
                webhook_url: data?.webhook_url,
              },
            },
          };
        }
        break;
      case Providers.BMW_CONTRACTS:
        if (
          data?.api_url &&
          data?.token_url &&
          data?.client_id &&
          data?.client_secret &&
          data?.webhook_url &&
          data?.franchise_code
        ) {
          requestData = {
            dealer_management_system: {
              bmw_deals: {
                api_url: data?.api_url,
                token_url: data?.token_url,
                client_id: data?.client_id,
                client_secret: data?.client_secret,
                webhook_url: data?.webhook_url,
                franchise_code: parseInt(data?.franchise_code),
              },
            },
          };
          configuration_payload = {
            signing_initiation: data?.signing_initiation,
          };
        }
        break;
      case Providers.BMW_PROGRAMS:
        if (
          data?.api_url &&
          data?.token_url &&
          data?.client_id &&
          data?.client_secret
        )
          requestData = {
            fni_products: {
              product_service: {
                api_url: data?.api_url,
                token_url: data?.token_url,
                client_id: data?.client_id,
                client_secret: data?.client_secret,
              },
            },
          };
        break;
      case Providers.ROUTEONE:
        if (
          data?.api_url &&
          data?.dealership_id &&
          data?.user_id &&
          data?.sha_body_content &&
          data?.hmac_ss &&
          data?.api_hash &&
          data?.hmac_id &&
          data?.partner_id &&
          data?.webhook_url
        )
          requestData = {
            dealer_management_system: {
              routeone: {
                api_url: data?.api_url,
                dealership_id: data?.dealership_id,
                user_id: data?.user_id,
                sha_body_content: data?.sha_body_content,
                hmac_ss: data?.hmac_ss,
                api_hash: data?.api_hash,
                hmac_id: data?.hmac_id,
                partner_id: data?.partner_id,
                webhook_url: data?.webhook_url,
              },
            },
          };
        break;
      case Providers.TRANSUNION:
        if (
          data?.api_url &&
          data?.system_id &&
          data?.system_password &&
          certificate &&
          data?.industry_code &&
          data?.member_code &&
          data?.subscriber_code &&
          data?.password &&
          data?.product_version &&
          data?.product_code_addon &&
          data?.time_to_live &&
          data?.caching_type
        )
          requestData = {
            credit_check: {
              transunion: {
                base_url: data?.api_url,
                system_id: data?.system_id,
                system_password: data?.system_password,
                certificate_file: certificate,
                product_version: data?.product_version,
                add_on_product_code: data?.product_code_addon,
                time_to_live: data?.time_to_live,
                caching_type: data?.caching_type,
                subscriber: {
                  industry_code: data?.industry_code,
                  member_code: data?.member_code,
                  inquiry_subscriber_prefix_code: data?.subscriber_code,
                  password: data?.password,
                },
              },
            },
          };
        configuration_payload = {
          caching_type: data?.caching_type,
        };
        break;
      case Providers.EXPERIAN:
        if (
          data?.api_url &&
          data?.username &&
          data?.password &&
          data?.client_id &&
          data?.client_secret &&
          data?.client_reference_id
        )
          requestData = {
            credit_application: {
              experian: {
                base_url: data?.api_url,
                username: data?.username,
                password: data?.password,
                client_id: data?.client_id,
                client_secret: data?.client_secret,
                client_reference_id: data?.client_reference_id,
              },
            },
          };
        break;
      case Providers.EQUIFAX:
        if (data?.api_url && data?.username && data?.password)
          requestData = {
            credit_application: {
              equifax: {
                base_url: data?.api_url,
                username: data?.username,
                password: data?.password,
              },
            },
          };
        break;
      case Providers.ASCENT:
        if (
          data?.api_url &&
          data?.token_url &&
          data?.client_id &&
          data?.client_secret &&
          data?.webhook_url
        )
          if (getValues("integration_type") == IntegrationType.CONTRACTING) {
            requestData = {
              ascent_configuration: {
                api_url: data?.api_url,
                token_url: data?.token_url,
                client_id: data?.client_id,
                client_secret: data?.client_secret,
                webhook_url: data?.webhook_url,
              },
            };
            configuration_payload = {
              signing_initiation: data?.signing_initiation,
            };
          } else if (
            getValues("integration_type") == IntegrationType.PRICING_PROGRAM
          ) {
            requestData = {
              ascent_configuration: {
                api_url: data?.api_url,
                token_url: data?.token_url,
                client_id: data?.client_id,
                client_secret: data?.client_secret,
                webhook_url: data?.webhook_url,
              },
            };
            configuration_payload = {
              default_pricing_program: data?.default_pricing_program,
            };
          } else {
            requestData = {
              ascent_configuration: {
                api_url: data?.api_url,
                token_url: data?.token_url,
                client_id: data?.client_id,
                client_secret: data?.client_secret,
                webhook_url: data?.webhook_url,
              },
            };
          }
        break;
      case Providers.BMW_PRICING:
        if (
          data?.api_url &&
          data?.token_url &&
          data?.client_id &&
          data?.client_secret &&
          data?.est_client_id &&
          data?.est_client_secret &&
          data?.franchise_code
        ) {
          requestData = {
            price_management_system: {
              bmw_pricing: {
                api_url: data?.api_url,
                token_url: data?.token_url,
                client_id: data?.client_id,
                client_secret: data?.client_secret,
                estimator_client_id: data?.est_client_id,
                estimator_client_secret: data?.est_client_secret,
                franchise_code: parseInt(data?.franchise_code),
              },
            },
          };
          configuration_payload = {
            default_pricing_program: data?.default_pricing_program,
          };
        }
        break;
      case Providers.BMW_DEALS:
        if (
          data?.api_url &&
          data?.token_url &&
          data?.client_id &&
          data?.client_secret &&
          data?.webhook_url &&
          data?.franchise_code
        ) {
          requestData = {
            dealer_management_system: {
              bmw_deals: {
                api_url: data?.api_url,
                token_url: data?.token_url,
                client_id: data?.client_id,
                client_secret: data?.client_secret,
                webhook_url: data?.webhook_url,
                franchise_code: parseInt(data?.franchise_code),
              },
            },
          };
        }
        break;
      case Providers.SHIFTDIGITAL:
        if (data?.host && data?.username && data?.password && data?.port)
          requestData = {
            inventory_server: {
              sd_inventory: {
                host: data?.host,
                username: data?.username,
                password: data?.password,
                port: data?.port,
              },
            },
          };
        break;
      case Providers.UPSCOPE:
        if (data?.script_key)
          requestData = {
            co_browsing: {
              upscope: {
                script_key: data?.script_key,
              },
            },
          };
        break;
      case Providers.GOOGLE:
        if (data?.api_secret_key)
          requestData = {
            address_verification: {
              google: {
                api_key: data?.api_secret_key,
              },
            },
          };
        break;
      case Providers.MARKETSCAN:
        if (data?.partner_id && data?.api_url)
          requestData = {
            price_management_system: {
              market_scan: {
                partner_id: data?.partner_id,
                api_url: data?.api_url,
              },
            },
          };
        break;
      case Providers.CDK:
        if (
          data?.api_url &&
          data?.department_id &&
          data?.api_secret &&
          data?.subscription_id &&
          data?.api_key &&
          data?.token_url
        )
          if (
            getValues("integration_type") == IntegrationType.CUSTOMER_MANAGEMENT
          ) {
            requestData = {
              dealer_management_system: {
                cdk: {
                  token_url: data?.token_url,
                  customer_api: {
                    api_url: data?.api_url,
                    api_secret: data?.api_secret,
                    api_key: data?.api_key,
                    subscription_id: data?.subscription_id,
                    department_id: data?.department_id,
                  },
                },
              },
            };
          } else if (
            getValues("integration_type") == IntegrationType.DEAL_PUSH
          ) {
            requestData = {
              dealer_management_system: {
                cdk: {
                  token_url: data?.token_url,
                  deal_api: {
                    api_url: data?.api_url,
                    api_secret: data?.api_secret,
                    api_key: data?.api_key,
                    subscription_id: data?.subscription_id,
                    department_id: data?.department_id,
                  },
                },
              },
            };
          } else {
            requestData = {
              dealer_management_system: {
                cdk: {
                  token_url: data?.token_url,
                  elead_customer_api: {
                    api_url: data?.api_url,
                    api_secret: data?.api_secret,
                    api_key: data?.api_key,
                    subscription_id: data?.subscription_id,
                    department_id: data?.department_id,
                  },
                },
              },
            };
          }
        break;
      case Providers.TEKION:
        if (
          data?.access_key &&
          data?.client_id &&
          data?.secret_key &&
          data?.api_url &&
          data?.dealer_id
        )
          requestData = {
            dealer_management_system: {
              tekion: {
                access_key: data?.access_key,
                client_id: data?.client_id,
                secret_key: data?.secret_key,
                api_url: data?.api_url,
                dealer_id: data?.dealer_id,
              },
            },
          };
        break;
      case Providers.DEALERTRACK:
        if (
          data?.username &&
          data?.password &&
          data?.enterprise_code &&
          data?.company_number &&
          data?.host &&
          data?.api_url
        )
          requestData = {
            dealer_management_system: {
              dealer_track: {
                dms: {
                  username: data?.username,
                  password: data?.password,
                  enterprise_code: data?.enterprise_code,
                  company_number: data?.company_number,
                  host: data?.host,
                  api_url: data?.api_url,
                },
              },
            },
          };

        break;
      case Providers.DEALER_TRACKML:
        if (
          data?.api_url &&
          data?.token_url &&
          data?.client_secret &&
          data?.api_key &&
          data?.client_id &&
          data?.webhook_url &&
          data?.source_partner_id
        )
          requestData = {
            dealer_management_system: {
              dealer_track: {
                multilender: {
                  api_key: data?.api_key,
                  client_id: data?.client_id,
                  api_url: data?.api_url,
                  client_secret: data?.client_secret,
                  token_url: data?.token_url,
                  webhook_url: data?.webhook_url,
                  source_partner_id: data?.source_partner_id,
                },
              },
            },
          };

        break;
      case Providers.PLAID:
        if (
          data?.client_secret &&
          data?.client_id &&
          data?.app_name &&
          data?.api_version &&
          data?.api_url
        )
          requestData = {
            account_verification: {
              plaid: {
                client_secret: data?.client_secret,
                client_id: data?.client_id,
                client_app_name: data?.app_name,
                client_webhook: "",
                api_url: data?.api_url,
                api_version: data?.api_version,
              },
            },
          };
        break;
      case Providers.STRIPE:
        if (data?.api_secret_key && data?.api_version)
          requestData = {
            payment: {
              stripe: {
                api_key: data?.api_secret_key,
                api_version: data?.api_version,
              },
            },
          };
      case Providers.PEN:
        if (data?.user_name && data?.password && data?.token && data?.api_url)
          requestData = {
            fni_products: {
              pen: {
                api_url: data?.api_url,
                user_name: data?.user_name,
                password: data?.password,
                user_token: data?.token,
              },
            },
          };
        break;
      case Providers.SOCURE:
        if (
          data?.api_secret_key &&
          data?.api_url &&
          data?.time_to_live &&
          data?.module_types
        )
          requestData = {
            fraud_identification: {
              socure: {
                api_key: data?.api_secret_key,
                api_url: data?.api_url,
                time_to_live: data?.time_to_live,
              },
            },
          };
        configuration_payload = {
          module_types: data?.module_types,
        };
        break;
      case Providers.MITEK:
        if (
          data?.client_id &&
          data?.client_secret &&
          data?.manual_client_id &&
          data?.manual_client_secret &&
          data?.manual_api_base_url &&
          data?.identity_api_base_url
        )
          requestData = {
            license_verification: {
              mitek: {
                mitek_account: {
                  identity_api_base_url: data?.identity_api_base_url,
                  identity_client_id: data?.client_id,
                  identity_client_secret: data?.client_secret,
                  manual_api_base_url: data?.manual_api_base_url,
                  manual_client_id: data?.manual_client_id,
                  manual_client_secret: data?.manual_client_secret,
                  retries_duration: 0,
                },
              },
            },
          };
        configuration_payload = {
          recapture_attempts_limit: data?.recapture_attempts_limit,
        };
        break;
      case Providers.KBB:
        if (data?.api_secret_key && data?.base_url)
          requestData = {
            vehicle_valuation: {
              kbb: {
                base_url: data?.base_url,
                api_key: data?.api_secret_key,
              },
            },
          };
        break;
      case Providers.DOCUSIGN:
        if (
          data?.api_base_url &&
          data?.account_id &&
          data?.client_id &&
          data?.secret_key &&
          data?.oauth_hostname &&
          data?.api_version &&
          data?.user_id &&
          data?.jwt_oauth_hostname &&
          data?.webhook_url
        )
          requestData = {
            e_sign: {
              docusign: {
                api_base_url: data?.api_base_url,
                account_id: data?.account_id,
                client_id: data?.client_id,
                secret_key: data?.secret_key,
                oauth_hostname: data?.oauth_hostname,
                jwt_oauth_hostname: data?.jwt_oauth_hostname,
                api_version: data?.api_version,
                user_id: data?.user_id,
                webhook_url: data?.webhook_url,
              },
            },
          };
        break;
      case Providers.WAY:
        if (data?.api_url && data?.auth_token && data?.webhook_url)
          requestData = {
            insurance_management: {
              way: {
                api_url: data?.api_url,
                auth_token: data?.auth_token,
                webhook_url: data?.webhook_url,
              },
            },
          };
        break;
      case Providers.SHIFT_DIGITAL:
        if (data?.api_url && data?.client_id && data?.provider_id)
          requestData = {
            tagging: {
              sd_tagging: {
                api_url: data?.api_url,
                client_id: data?.client_id,
                provider_id: data?.provider_id,
              },
            },
          };
        break;
      case Providers.PAYOFF_ROUTEONE:
        if (
          data?.api_url &&
          data?.soap_action_url &&
          data?.username &&
          data?.password &&
          data?.partner_id
        )
          requestData = {
            payoff_quotes: {
              routeone_payoff: {
                api_url: data?.api_url,
                soap_action_url: data?.soap_action_url,
                username: data?.username,
                password: data?.password,
                partner_id: data?.partner_id,
              },
            },
          };
        break;
      case Providers.HOMENET:
        if (data?.api_url && data?.secret_key)
          requestData = {
            inventory_management: {
              homenet: {
                api_url: data?.api_url,
                secret_key: data?.secret_key,
              },
            },
          };
        break;
      case Providers.LIGHTICO:
        if (
          data?.base_url &&
          data?.client_id &&
          data?.client_secret &&
          data?.api_version &&
          data?.token_api_version
        )
          requestData = {
            e_sign: {
              lightico: {
                base_url: data?.base_url,
                client_id: data?.client_id,
                client_secret: data?.client_secret,
                api_version: data?.api_version,
                token_api_version: data?.token_api_version,
              },
            },
          };
        break;
      default:
        isCredentialsAdded = true; //for now being
    }
    if (requestData) {
      hubexConfigurations
        ? await updateHubexCredentials(
            {
              data: requestData,
            },
            {
              onError: async (error: any) => {
                isCredentialsAdded = false;
                setSaveButtonState(false);
              },
            }
          )
        : await saveHubexCredentials(
            {
              data: requestData,
            },
            {
              onError: async (error) => {
                if (error?.code && error?.code === 403) {
                  actions.setToast({
                    toastMessage:
                      "Please Subscribe Hubex to add Integration(s)",
                    toastState: true,
                    variant: "error",
                  });
                } else {
                  actions.setToast({
                    toastMessage: "An error occurred while saving credentials",
                    toastState: true,
                    variant: "error",
                  });
                }

                isCredentialsAdded = false;
                setSaveButtonState(false);
              },
            }
          );
    }
    return { isCredentialsAdded, configuration_payload };
  };
  const onRemoveFile = () => {
    setFiles([]);
  };

  const onimageUpload = (e) => {
    setImageLoader(true);
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      const uploadfile = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = (reader.result as string).split(",")[1];
        setFiles((prevData) => [
          ...prevData,
          {
            base64: base64String,
            name: uploadfile.name,
          },
        ]);
        setCertificate(base64String);
        setValue("certificate_file", base64String);
      };
      reader.readAsDataURL(uploadfile);
      setImageLoader(false);
    }
  };
  const onimageDrop = (e) => {
    setImageLoader(true);
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      const uploadfile = e.target.dataTransfer[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = (reader.result as string).split(",")[1];
        setFiles((prevData) => [
          ...prevData,
          {
            base64: base64String,
            name: uploadfile.name,
          },
        ]);
        setCertificate(base64String);
        setValue("certificate_file", base64String);
      };
      reader.readAsDataURL(uploadfile);
      setImageLoader(false);
    }
  };

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <form>
              <Controller
                name="integration_type"
                control={control}
                rules={validation("Integration Type", true)}
                defaultValue={integrationTypeData?.integration_type}
                render={({ field: { onChange, value } }) => (
                  <Select
                    theme={theme}
                    label={"Integration Type"}
                    placeholder={"Select"}
                    value={
                      actionType == ActionOptions.EDIT
                        ? integrationTypeData?.integration_type
                        : value
                        ? value
                        : ""
                    }
                    items={
                      actionType == ActionOptions.EDIT
                        ? [
                            {
                              text: integrationTypeData?.integration_type,
                              value: integrationTypeData?.integration_type,
                            },
                          ]
                        : integrationOptions
                    }
                    onChange={(e) => {
                      onChange(e);
                      setValue("provider_name", "");
                      setProviderValues(e.target.value);
                      setInputFields([]);
                      watch("integration_type") ==
                      IntegrationType.PURCHASE_INSURANCE
                        ? setValue("route", "Index")
                        : setValue("route", "Hubex");
                    }}
                    sxProps={undefined}
                    selectError={errors?.integration_type?.message}
                    disabled={actionType === ActionOptions.EDIT}
                  />
                )}
              />
              <Controller
                name="route"
                control={control}
                defaultValue={"Hubex"}
                render={({ field: { value, onChange } }) => (
                  <Select
                    theme={theme}
                    label={"Route"}
                    placeholder={"Select"}
                    items={
                      ["Purchase Insurance"].includes(watch("integration_type"))
                        ? [{ text: "Index", value: "Index" }]
                        : watch("integration_type") ==
                          IntegrationType.INVENTORY_MANAGEMENT
                        ? [
                            { text: "Hubex", value: "Hubex" },
                            { text: "Index", value: "Index" },
                          ]
                        : [{ text: "Hubex", value: "Hubex" }]
                    }
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                )}
              />
              <Controller
                name="provider_name"
                control={control}
                defaultValue={""}
                rules={validation("Provider", true)}
                render={({ field: { onChange, value } }) => (
                  <Select
                    theme={theme}
                    label={"Provider"}
                    items={providerItems}
                    value={value ?? ""}
                    onChange={(e) => {
                      handleInputFields(e.target.value as string);
                      onChange(e);
                    }}
                    sxProps={undefined}
                    selectError={errors?.provider_name?.message}
                  />
                )}
              />
              {watch("provider_name") == Providers.MARKETSCAN &&
              watch("integration_type") ==
                IntegrationType.CALCULATION_AND_TAXATION ? (
                <Controller
                  name="tax_service_type"
                  control={control}
                  defaultValue={"Tax and calculations"}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      theme={theme}
                      label={"Service Enabled"}
                      placeholder={"Select"}
                      items={[
                        {
                          text: "Tax and calculations",
                          value: "Tax and calculations",
                        },
                      ]}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              ) : (
                ""
              )}
              {useHasPermissions(
                indexPermissions.MENU.PREFERENCES,
                PermissionTypes.VIEW
              ) &&
              (watch("provider_name") == Providers.BMW_CONTRACTS ||
                watch("provider_name") == Providers.ASCENT) &&
              watch("integration_type") == IntegrationType.CONTRACTING ? (
                <Controller
                  name="signing_initiation"
                  control={control}
                  defaultValue={"No"}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      theme={theme}
                      label={"Signing Initiation Required"}
                      placeholder={"Select"}
                      items={[
                        {
                          text: "No",
                          value: "No",
                        },
                        {
                          text: "Yes",
                          value: "Yes",
                        },
                      ]}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              ) : null}
              {(watch("provider_name") == Providers.BMW_PRICING ||
                watch("provider_name") == Providers.ASCENT) &&
              watch("integration_type") == IntegrationType.PRICING_PROGRAM ? (
                <Controller
                  name="default_pricing_program"
                  control={control}
                  defaultValue={""}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      theme={theme}
                      label={"Default Pricing Program"}
                      placeholder={"Select"}
                      items={[
                        {
                          text: "Maximum Rate",
                          value: "Maximum Rate",
                        },
                        {
                          text: "Minimum Rate",
                          value: "Minimum Rate",
                        },
                      ]}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              ) : (
                ""
              )}
              {inputFields &&
                inputFields?.map((inputField, index) => {
                  if (inputField?.data_type === "text") {
                    return (
                      <Controller
                        key={index}
                        name={inputField.name}
                        control={control}
                        defaultValue={inputField?.default_value ?? ""}
                        rules={{
                          required:
                            inputField.label !==
                            "Recapture Attempts Limit (Optional)"
                              ? {
                                  value: true,
                                  message: `${inputField.label} is required`,
                                }
                              : false,
                          validate: (value) => {
                            if (
                              inputField.name === "recapture_attempts_limit"
                            ) {
                              if (value === "") return true;
                              if (!/^\d+$/.test(value)) {
                                return "Only whole numbers (0-99) are allowed";
                              }
                              if (parseInt(value, 10) > 99) {
                                return "Number must be between 0 and 99";
                              }
                              return true;
                            }
                            if (value.trim() === "") {
                              return `${inputField.label} is required`;
                            }
                            if (
                              INTEGRATION_VALIDATION_FIELDS.includes(
                                inputField.name
                              ) &&
                              !/^(https?:\/\/|http:\/\/)[^\s$.?#].[^\s]*$/.test(
                                value
                              )
                            ) {
                              return "Please enter a valid URL";
                            }

                            if (inputField?.name === "time_to_live") {
                              if (!/^\d+$/.test(value)) {
                                return "Invalid number";
                              }
                            }
                            return true;
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            theme={theme}
                            fullWidth={true}
                            label={inputField.label}
                            placeholder={"Type here..."}
                            type="text"
                            {...field}
                            value={field.value?.trimStart()}
                            error={errors?.[inputField.name]?.message}
                            endAdornment={
                              watch("provider_name") == Providers.SOCURE &&
                              inputField?.name === "time_to_live"
                                ? "Days"
                                : ""
                            }
                          />
                        )}
                      />
                    );
                  } else if (inputField?.data_type == "select") {
                    return (
                      <Controller
                        key={index}
                        name={inputField.name}
                        control={control}
                        defaultValue={inputField?.items[0].value}
                        rules={{
                          required: {
                            value: true,
                            message: `${inputField.label} is required`,
                          },
                        }}
                        render={({ field: { value, onChange } }) => (
                          <Select
                            id="selectDrawer"
                            name="selectName"
                            theme={theme}
                            label={inputField?.label}
                            items={inputField?.items}
                            value={value}
                            sxProps={""}
                            onChange={onChange}
                            selectError={errors?.[inputField.name]?.message}
                          ></Select>
                        )}
                      />
                    );
                  } else if (inputField?.data_type === "multiselect") {
                    return (
                      <Controller
                        key={index}
                        name={inputField?.name}
                        defaultValue={inputField?.items
                          ?.filter(
                            (item) => item?.value !== "documentverification"
                          )
                          ?.map((item) => item?.value)}
                        control={control}
                        render={({ field }) => (
                          <MultiSelect
                            sxProps={{ m: 0, width: "100%" }}
                            theme={theme}
                            label={inputField?.label}
                            value={field.value}
                            items={inputField?.items}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    );
                  }
                })}
              {getValues("provider_name") == Providers.TRANSUNION &&
              getValues("integration_type") == IntegrationType.CREDIT_BUREAU ? (
                <FileDragDropWrap className="mb-24">
                  <Controller
                    name="certificate_file"
                    control={control}
                    rules={{
                      validate: (_value) => {
                        if (
                          !watch("certificate_file") ||
                          watch("certificate_file") == ""
                        ) {
                          return "Certificate File is required";
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <Tooltip
                        theme={theme}
                        title={"Certificate File is required"}
                      >
                        <FileDragDrop
                          {...field}
                          hoverLabel="Drag and drop certificate file, or browse"
                          width="300"
                          height="200px"
                          backgroundColor="white"
                          onDrop={onimageDrop}
                          onChange={onimageUpload}
                          theme={theme}
                          error={
                            errors?.certificate_file?.message ? true : false
                          }
                        />
                      </Tooltip>
                    )}
                  />
                  <FilePreview
                    files={files && files.length > 0 ? files : []}
                    theme={theme}
                    preview="fileIcon"
                    onRemoveFile={onRemoveFile}
                  />
                  {imageLoader && (
                    <ImgLoader theme={theme} sx={{ mb: 1, mt: 3 }}>
                      <CircleLoader theme={theme} size="xs" />
                    </ImgLoader>
                  )}
                </FileDragDropWrap>
              ) : (
                ""
              )}

              <Controller
                name="is_active"
                control={control}
                defaultValue={true}
                render={({ field: { value, onChange } }) => (
                  <Select
                    theme={theme}
                    label={"Status"}
                    placeholder={"Select"}
                    items={[
                      { text: "Enabled", value: true },
                      { text: "Disabled", value: false },
                    ]}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    sxProps={undefined}
                    disabled={true}
                  />
                )}
              />
            </form>
          </>
        }
        customFooter={
          <>
            <Button
              theme={theme}
              primary
              type="submit"
              text={"Save"}
              fullWidth
              onClick={handleSubmit(onSubmit)}
              disabled={saveButtonState}
            />
          </>
        }
      />
    </>
  );
};
export default AddNewIntegration;
