// EditorComponent.tsx
import { Editor } from "@tinymce/tinymce-react";
import { RawEditorOptions } from "tinymce";
import { FC, ReactNode } from "react";
import { styled, useTheme } from "@mui/material";
import { Box, IBoxProps, Label, Typography } from "@ntpkunity/controls";
import tinymce from "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/models/dom/model";
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autosave";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/codesample";
import "tinymce/plugins/directionality";
import "tinymce/plugins/importcss";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/preview";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/save";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";

// Styles
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/themes/silver";
import "../../styles/font-family.css";
import { StyleVariables } from "../../styles/theme";

export const TextEditorWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.text-editor-wrap": {
    ".text-editor-container": {
      ".u-input-label": {
        fontFamily: theme.typography.fontFamily,
        fontSize: "1rem",
        lineHeight: theme.typography.body1.lineHeight,
        marginBottom: 4,
        display: "block",
        width: "100%",
        transition: "all 0.3s",
      },
      ".u-error-text": {
        fontSize: "0.75rem",
        color: theme.palette.error.main,
        marginLeft: 15,
        marginTop: 3,
        lineHeight: theme.typography.caption.lineHeight,
        transition: "all 0.3s",
      },
      ".tox-tinymce": {
        borderColor:
          theme.palette.mode === "light"
            ? StyleVariables.black20
            : StyleVariables.lightWhite,
        borderWidth: 1,
        borderRadius: theme.shape.borderRadius,

        ".tox-editor-container": {
          border: "1px solid transparent",
          borderRadius: theme.shape.borderRadius,
        },

        "&:hover": {
          borderColor:
            theme.palette.mode === "light"
              ? theme.palette.grey[800]
              : theme.palette.common.white,
        },
      },
      ".tox": {
        backgroundColor: theme.palette.common.white,
        ".tox-editor-header": {
          position: "absolute",
          width: "calc(100% - 2px)",
          bottom: -50,
          left: 1,
          transition: "all 0.3s",
          borderWidth: 0,
          paddingLeft: 8,
          display: "flex",
          justifyContent: "center",
          borderRadius: "0 0 8px 8px",
          ".tox-toolbar-overlord, .tox-toolbar__primary": {
            backgroundColor: "transparent",
          },
          ".tox-toolbar__group": {
            padding: 0,
            ".tox-tbtn": {
              "&:hover": {
                backgroundColor: theme.palette.grey[50],
              },
            },
          },
        },
        ".tox-edit-area__iframe": {
          transition: "all 0.3s",
        },
        "&:hover": {
          ".tox-editor-header": {
            bottom: 1,
          },
          ".tox-edit-area__iframe": {
            height: "calc(100% - 46px)",
          },
        },
      },
    },
    "&.error": {
      transition: "all 0.3s",
      ".text-editor-container": {
        ".u-input-label": {
          color: theme.palette.error.main,
        },
        ".tox-tinymce": {
          transition: "all 0.3s",
          borderColor: theme.palette.error.main,
          ".tox-editor-container": {
            borderColor: theme.palette.error.main,
          },
        },
      },
    },
  },
}));

const defaultInit = {
  height: 200,
  menubar: false,
  plugins: "lists format link | importcss",
  toolbar:
    "bold italic underline | bullist numlist | outdent indent | removeformat | link",
  content_style:
    ' @font-face {font-family: "UnityFontFamily";src: url("https://unitydmsbucket.s3.amazonaws.com/c6a592Inter.eot")format("embedded-opentype"),url("https://unitydmsbucket.s3.amazonaws.com/5140cbInter.woff2")format("woff2"),url("https://unitydmsbucket.s3.amazonaws.com/c341ffInter.woff")format("woff"),url("https://unitydmsbucket.s3.amazonaws.com/8b600dInter.ttf")format("truetype");} body { font-family:UnityFontFamily; font-size: 16px; color: rgba(0,0,0,0.87): scrollbar-width: thin; } body::-webkit-scrollbar {width:7px; height:7px} body::-webkit-scrollbar-track{padding:0 1px;background-color:#f0f0f0} body::-webkit-scrollbar-thumb{transition:all 0.3s;background-color:#cdcdcd} body::-webkit-scrollbar-thumb:hover{background-color:#a6a6a6}',
  branding: false,
  skin: false,
  width: "100%",
  table_grid: false,
  statusbar: false,
  toolbar_mode: "sliding" as "sliding",
  file_picker_callback: (cb: any) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.addEventListener("change", (e: any) => {
      const file = e.target.files[0];
      const reader: any = new FileReader();
      reader.addEventListener("load", () => {
        const id = "blobid" + new Date().getTime();
        const blobCache = tinymce.activeEditor?.editorUpload.blobCache;
        const base64 = reader.result.split(",")[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);
        cb(blobInfo.blobUri(), { title: file.name });
      });
      reader.readAsDataURL(file);
    });
    input.click();
  },
};

interface EditorProps {
  init?: Record<string, any>;
  [key: string]: any;
  label?: string;
  helpText?: ReactNode;
}

const EditorComponent: FC<EditorProps> = ({
  label,
  editor,
  helpText,
  ...props
}) => {
  const { init = { ...tinymce.init, icons: "small" }, ...rest } = props;
  const mergedInit = { ...defaultInit, ...init };
  const theme = useTheme();
  return (
    <Box theme={theme} className="text-editor-container">
      <Typography
        theme={theme}
        variant="body1"
        component="label"
        className="u-input-label"
        children={label}
      />
      <Editor init={mergedInit} {...rest} />
      {helpText}
    </Box>
  );
};

export default EditorComponent;
